import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from '../../components/navbar/navbar';
import { Link } from "react-router-dom"
import {Animated} from "react-animated-css";
import { connect } from "react-redux";
import { setPropety, getPropety } from "../../actions/order";
import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"
import { Helmet } from 'react-helmet';

import '../../components/step-amount/stepAmount.scss';

class StepAmount extends React.Component{

  componentDidMount() {
    document.querySelector('.background-left').style = 'background-color: #4EB0A3'
    document.querySelector('.background-right').style = 'background-color: #FDDD78'
    document.querySelector('.background-right-resp').style = 'background-color: #4EB0A3'
    // return to before step if is not fill in
    let currentStep = 3
    if (!checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }

  }

  constructor(props){
    super(props);

    this.state = {
      amount: this.props.getPropetyInView("people_count"),
      errors: {},
    }
  }


  handleValidation(){
    let errors = {};
    let formIsValid = true;
    if (this.props.setPropetyInView) {
      this.props.setPropetyInView(this.state.amount, "people_count")
      this.props.history.push('/plan')
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  contactSubmit(e){

    e.preventDefault();

    if(this.handleValidation()){
      console.log("Form submitted");

    }else{
      console.log("Form Error");
    }

  }


    handleChange(e){
      this.setState({ amount: e.target.value });
    }

  render(){
    return (

      <div>
      <Helmet>
          <title>comensales</title>
          <meta name="description" content="Le preguntamos al usuario para cuántas personas quiere su menú" />
      </Helmet>

      <section>
      <Navbar/>
      </section>
      <Row className="height-view">
        <div className="background-left-amount col-md-6">
          <div className="offset-md-2 mb-5">
            <div className="mt-5 col-md-10" >
            <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={0} isVisible={true}>
            <Link to="/feeding">
              <p className="text-back-amount"><img src="../back.png" alt="volver"/> Volver</p>
            </Link>
            </Animated>
            <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={1000} isVisible={true}>
              <h2 className="text-subinit-amount mt-4 mr-2">¿PARA CUÁNTAS PERSONAS QUIERES TU MENÚ?</h2>
            </Animated>

            </div>
          </div>
        </div>

      <Col className="background-right-amount" md={6}>
      <form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)} >

        <fieldset>
        <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
          <div className="offset-md-1 mt-md-0 mt-5">
            <div className="col-md-6">
              <img src="../amount.png" className="img-resp-amount" alt="amount"/>
            </div>
          </div>

          <div className="offset-md-2 offset-1">
            <Row className="mt-5 mb-5">

                <div className="col-md-5">
                  <input name="input-amount" placeholder="Ej. 3" type="number" className="amount mt-3 borde-amount" onChange={this.handleChange.bind(this)} value={this.state.amount} required/>
                  <label className="mt-1 control-label-amount align-bottom">Ingresa el número de personas</label>
                </div>
              <div className="col-md-5">
                <button id="submit" value="Submit" type="submit" className="btn btn-green-steps-amount">SIGUIENTE
                  <img className="image ml-1" src="../after.png" alt="next"/>
                  <img className="image ml-1" src="../after-green.png" alt=""/>
                </button>
              </div>
            </Row>
          </div>
        </Animated>


        </fieldset>
      </form>

      </Col>

      </Row>
      </div>
    )
  }
}

const mapStateToProps = dispatch => ({
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  },
  getPropetyInView: (property) => {
    return dispatch(getPropety(property))
  },
});

export default connect(null, mapStateToProps)(StepAmount);
