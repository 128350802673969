import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Navbar from '../../components/navbar/navbar';
import { Link } from "react-router-dom"
import {Animated} from "react-animated-css";
import '../../components/step-plan/stepPlan.scss';
import { connect } from "react-redux";
import { setPropety, getPropety } from "../../actions/order";
import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"
import { Helmet } from 'react-helmet';

import Skeleton from 'react-loading-skeleton';

import {config } from '../../config'
const HOST = config.HOST;

class StepPlan extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      plans: [],
      error: null,
      id_plan: this.props.getPropetyInView("id_plan"),
      id_order: 0,

    };
  }

  componentDidMount() {
    this.fetchPlans();
    document.querySelector('.background-left').style = 'background-color: #7ACCC6'
    document.querySelector('.background-right').style = 'background-color: #4EB0A3'
    document.querySelector('.background-right-resp').style = 'background-color: #7ACCC6'

    let currentStep = 4
    if (!checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }
  }

  fetchPlans() {
    fetch(HOST + 'v1/plans')
    .then(response => response.json())
    .then(data =>
      this.setState({
        plans: data,
        isLoading: false,
      })
    )
    .catch(error => this.setState({ error, isLoading: false }));
  }

  planClick(id_plan){
    if (this.props.setPropetyInView) {
      this.props.setPropetyInView(id_plan, "id_plan")
      this.props.history.push('/choose-menu')
    }
  }

  render(){
    const { isLoading, plans, error } = this.state;
    const loadingGrid = [1,2,3];
    return (

      <div>
      <Helmet>
          <title>elige tu plan</title>
          <meta name="description" content="Aquí el usuario puede elegir un tipo de plan. Puede elegir entre el plan de 1 mes por US$ 8.99 El plan de 3 meses por US$ 6.99 al mes o el plan de 12 meses por US$ 5.99 al mes" />
      </Helmet>

      <section>
      <Navbar/>
      </section>

      <Row className="height-view">
        <Col className="background-left-plan" md={6}>
          <div className="offset-md-2 mb-5">
            <Col className="mt-5" md={10}>
            <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={0} isVisible={true}>
              <Link to="/amount">
                <p className="text-back-plan"><img src="../back.png" alt="volver"/> Volver</p>
              </Link>
                <h2 className="mt-4 text-init-plan">¡PERFECTO!</h2>
            </Animated>
            <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={1000} isVisible={true}>
              <h2 className="text-subinit-plan">AHORA ELIGE UNO DE NUESTROS PLANES</h2>
            </Animated>

            </Col>
          </div>
        </Col>

      <Col className="background-right-plan position-relative" md={6}>
      <div className="offset-md-1">
      <div className="space-plan col-4">

      {error ? <p>{error.message}</p> : null}

      {!isLoading ? (
        plans.map(plan => {
          const { id, price, count_monts } = plan;
          const activeClass = (id === this.state.id_plan) ? "active" : ""
          return (
            <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
              <button onClick={() => this.planClick(id)} type="submit" className={ "mb-3 btn-celeste-steps-plan " + activeClass  }>
                <Row className="">
                    <div className="col-5">
                      <span className="float-left number-month-plan  ml-3">{count_monts}</span>
                    {
                      count_monts > 2 ?<span className="float-left month-plan ml-2">meses</span> : <span className="float-left month-plan ml-2">mes</span>
                    }
                    </div>
                    <center>
                    <img className="" src="../line.png" alt="separator"/>
                    </center>
                    <div className="col-6">
                    {
                      count_monts > 2 ? <span className="value-plan-plan">US ${price}<span className="slah-mes"> /por mes</span> </span>: <span className="value-plan-plan">US ${price}<span className="slah-mes"></span> </span>
                    }
                    </div>
                </Row>
              </button>
            </Animated>


          );
        })
      ) : (
        // Loading skeleton
        loadingGrid.map(item => { return (
          <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
            <div className={ "mb-3 btn-celeste-steps-plan " }>
              <Row className="pt-3">
                <div className="col-5 ml-3 pt-3">
                  <Skeleton />
                </div>
                <center>
                <img className="" src="../line.png" alt="separator"/>
                </center>
                <div className="col-5  pt-3">
                  <Skeleton/>
                </div>
              </Row>
            </div>
          </Animated>
        );
      })
      )}
      </div>

      </div>
      <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
        <img className="d-none d-md-block avocado position-absolute" src="../avocado.png" alt="aguacate"/>
      </Animated>
      </Col>

      </Row>
      </div>
    )
  }
}
const mapStateToProps = dispatch => ({
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  },
  getPropetyInView: (property) => {
    return dispatch(getPropety(property))
  },

});

export default connect(null, mapStateToProps)(StepPlan);
