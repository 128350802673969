import Api from "../core/api";

const getPlans = () => {
  return Api.exec("v1/plans", {}, "GET").then((response) => {

    if (response) {
      return response;
    }

    return []
  });
};


const getPlan = (data) => {
  return Api.exec("v1/plans/view", { data }, "GET").then((response) => {
    if (response) {
      return response;
    }

    return null
  });
};


export default {
  getPlan,
  getPlans
};
