import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../components/menu/menu.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;

const Menu = (props) => {

  return (
    <>

    <Modal className="modal-blog modal-how" show={props.modalOpen} onHide={props.handleModalOpen}>

    <Col className="background-left modal-how" md="12">
    <Row>
    <Col className="offset-md-1" md={9}>
    <a href={WEB_ROOT}>
      <div className="navbar-brand mt-md-5 mt-2">
      <img src="../logo.png"  className="d-inline-block align-top" alt="logo"/>
      </div>
    </a>

    <div className="float-right d-md-none navbar-brand mt-4">
    <img src="../menuwhite.png" onClick={props.handleModalOpen} className="d-inline-block cursor-style align-top" alt="menu"/>
    </div>


    </Col>

    <Col className="d-none d-sm-none d-md-block" md={2}>
    <div className="navbar-brand mt-5">
    <img src="../menuwhite.png" onClick={props.handleModalOpen} className="d-inline-block cursor-style align-top" alt="menu"/>
    </div>
    </Col>
    </Row>
    </Col>

    <Row className='modal-how' md={12}>
    <Col className="offset-md-2 mt-4" md={8}>
    <a className="d-block text-how text-center" href="https://almorzadiario.com/pasos/">CÓMO FUNCIONA</a>
    <a className="d-block text-how text-center" href="https://almorzadiario.com/acerca-de-almorzadiario/">ACERCA DE ALMORZADIARIO</a>
    <a className="d-block text-how text-center" href="https://almorzadiario.com/preguntas-frecuentes/">PREGUNTAS FRECUENTES</a>
    <a className="d-block text-how text-center" href="https://almorzadiario.com/blog/">BLOG</a>


    </Col>
    </Row>
    <Row className="modal-how" md={12}>
    <Col className="offset-md-4" md={4}>
    <Row className="mt-3">

    <Col className="d-block text-center mt-5" md={6}>
    <a rel="noopener noreferrer" target="_blank" className="alingncenter" href="https://www.facebook.com/Almorzadiario/">
    <img src="../facebook.png" className="d-inline-block cursor-style align-top" alt="logo"/>
    </a>
    </Col>

    <Col className="d-block text-center mt-5" md={6}>
    <a rel="noopener noreferrer"  className=" alingncenter" href="https://www.instagram.com/almorzadiario/" target="_blank">
    <img src="../instagram.png" className="menu-img d-inline-block cursor-style align-middle" alt="logo"/>
    </a>
    </Col>
    </Row>

    </Col>

    </Row>
    </Modal>
    </>
  );
}

export default Menu;
