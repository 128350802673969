import React from 'react';
import {Link} from 'react-router-dom';
import LoginModal from '../../components/login/login';
import MenuModal from '../../components/menu/menu';
import Dropdown from 'react-bootstrap/Dropdown'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SingUpModal from '../../components/sign-up/signUp';
import PasswordModal from '../../components/forgot-password/ForgotPassword';
import { logout } from "../../actions/auth";
import { connect } from "react-redux";
import "../../core/sass/navbar.scss";
import {compose} from "redux";
import { withRouter } from 'react-router';

import { clearMessage } from "../../actions/message";

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;

class NavbarGray extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loginModal: true,
      signupModal: false,
      modalOpen: false,
      modalOpenMenu: false,
      passwordModal: false,
      login_closed_intents: 0,
      singup_closed_intents: 0
    };

    this.handleModalOpenLogin = this.handleModalOpenLogin.bind(this);

  }


 handleModalOpenLogin = () => {
    this.props.clearMessage()
    if (this.state.login_closed_intents >= 1) {
      this.props.history.push("/create-order");
      window.location.reload();
    }

    this.setState((prevState) => {
      return {
        message: "",
        modalOpen: !prevState.modalOpen,
        login_closed_intents: prevState.login_closed_intents + 1
      }
    })
  }

  handleModalOpenSignUp = () => {
    this.props.clearMessage()
    if (this.state.singup_closed_intents >= 1) {
      this.props.history.push("/create-order");
      window.location.reload();
    }

    this.setState((prevState) => {
      return {
        message: "",
        modalOpen: !prevState.modalOpen,
        singup_closed_intents: prevState.singup_closed_intents + 1
      }
    })
  }

  handleModalOpen = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return {
        message: "",
        modalOpen: !prevState.modalOpen
      }
    })
  }

  handleOpenMenu  = () => {
    this.setState((prevState) => {
      return{
        modalOpenMenu: !prevState.modalOpenMenu
      }
    })
  }

  handleChangeModal = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return {
        message: "",
        loginModal: !prevState.loginModal,
        signupModal: !prevState.signupModal,
        modalOpen: true,
      }
    })
  }

  handleChangeModalPassword = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return{
        message: "",
        loginModal: !prevState.loginModal,
        passwordModal: !prevState.passwordModal,
        modalOpen: true,
      }
    })
  }

  render() {
    const isLoggedIn = this.props.isLoggedIn
    return (
      <div className="row navbar-top back-division">
      <div className="background-left col-6 col-md-4">
      <div className="offset-md-3">
      <Col md={10}>
      <a href={WEB_ROOT}>
      <div className="navbar-brand mt-md-5 mt-2">
      <img src="../homegreen.png"  className="d-inline-block align-top" alt=""/>
      </div>
      </a>
      </Col>
      </div>
      </div>

        <div className="d-none d-md-block background-right float-right col-6 col-md-8">
          <div className="offset-2 offset-md-8">
            <Row>
              <div className="col-md-2 mr-lg-3 mr-md-4 mr-xs-6">
              {
                isLoggedIn ?
                <Dropdown className="mt-md-5 mt-4" alignRight onSelect={this.handleSelected}>

                <Dropdown.Toggle className="navbar-brand dropdown-color" id="dropdown-basic">
                <img src="../key-black.png"  alt="key" className="d-inline-block align-top  key-account-gray" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="text-right dropdown-background">
                <Dropdown.Item className="focus-item-nav" as={Link} to="/create-order">Crear recetario</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" as={Link} to="/profile">Perfil</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" as={Link} to="/">Historial</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" onClick={this.props.logOut}>Salir</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                :
                <button className="mt-md-5 mt-4 btn-action-nav" onClick={this.handleModalOpen}>
                <img src="../key-black.png"  alt="menu" className="d-inline-block align-top key-account-gray" />
                </button>

              }
              </div>

              <div className="col-md-2">
              <button className="mt-md-5 mt-4 btn-action-nav" onClick={this.handleOpenMenu}>
              <img src="../menu-black.png"  alt="menu" className="mt-1 d-inline-block align-top" />
              </button>

              </div>
            </Row>
          </div>
        </div>

        <div className="d-block d-md-none background-right-resp float-right col-6 col-md-8">
          <div className="">
            <Row>
              <div className="col-5 col-sm-3">
              {
                isLoggedIn ?
                <Dropdown className="mt-md-5 mt-4" alignRight onSelect={this.handleSelected}>

                <Dropdown.Toggle className="navbar-brand dropdown-color" id="dropdown-basic">
                <img src="../key-black.png"  alt="key" className="d-inline-block align-top  key-account-gray" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="text-right dropdown-background">
                <Dropdown.Item className="focus-item-nav" as={Link} to="/create-order">Crear recetario</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" as={Link} to="/profile">Perfil</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" as={Link} to="/">Historial</Dropdown.Item>
                <Dropdown.Item className="focus-item-nav" onClick={this.props.logOut}>Salir</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                :
                <button className="mt-md-5 mt-4 btn-action-nav" onClick={this.handleModalOpen}>
                <img src="../key-black.png"  alt="menu" className="d-inline-block align-top key-account-gray" />
                </button>

              }
              </div>

              <div className="col-5">
              <button className="mt-md-5 mt-4 btn-action-nav" onClick={this.handleOpenMenu}>
              <img src="../menu-black.png"  alt="menu" className="mt-1 d-inline-block align-top" />
              </button>

              </div>
            </Row>
          </div>
        </div>

      { this.state.loginModal &&
        <LoginModal
          modalOpen={this.state.modalOpen}
          handleModalOpen={this.handleModalOpenLogin}
          handleChangeModal={this.handleChangeModal}
          handleChangeModalPassword={this.handleChangeModalPassword}
          />
      }

      { this.state.signupModal &&
        <SingUpModal
        modalOpen={this.state.modalOpen}
        handleModalOpen={this.handleModalOpenSignUp}
        handleChangeModal={this.handleChangeModal}
        />
      }

      { this.state.passwordModal &&
        <PasswordModal
        modalOpen={this.state.modalOpen}
        handleModalOpen={this.handleModalOpen}
        handleChangeModal={this.handleChangeModalPassword}
        />
      }

      <MenuModal
      modalOpen={this.state.modalOpenMenu}
      handleModalOpen={this.handleOpenMenu}
      />
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  logOut: () => {
    return dispatch(logout())
  },
  clearMessage: () => {
    dispatch(clearMessage())
  },
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps))(NavbarGray);
