import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom"
import { MDBIcon } from "mdbreact";
import 'font-awesome/css/font-awesome.min.css';
import '../../components/chosen-plan/choosenPlan.scss';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { required } from "../../core/validations";

import { setPropety, getPropety } from "../../actions/order";

import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"

import { getPlan } from "../../actions/plan";

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;

class ChoosePlan extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      plan: null,
      error: null,
      cupon_code: this.props.getPropetyInView("code"),
    };
  }

  componentDidMount() {
    this.fetchPlan();

    let currentStep = 6
    if (!checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }
  }

  fetchPlan() {
    this.setState({ isLoading: true})

    if (!this.props.match || !this.props.match.params ||  !this.props.match.params.id) {
      return
    }

    let data = {id: this.props.match.params.id}

    if (this.state.cupon_code !== "" || this.state.cupon_code !== null) {
      data["code"] = this.state.cupon_code
    }

    this.props.getPlanInView(data)
    .then(data => {
      this.setState({
        plan: data,
        isLoading: false,
      })
    }
  )
  .catch(error => this.setState({ error, isLoading: false }));
}

onChangeCuponCode = (e) => {
  this.setState({cupon_code: e.target.value});
};

handleForm = (e) => {
  e.preventDefault();

  this.setState({successful: false});

  this.form.validateAll();

  if (this.checkBtn.context._errors.length === 0) {
    this.props.setPropetyInView(this.state.cupon_code, "code")
    window.location.reload();
  }
};

render() {
  let plan = this.state.plan

  return (

    <div>
    <Helmet>
        <title>resumen del plan</title>
        <meta name="description" content="Aquí el usuario encontrará el resumen de su plan. Podrá ver el valor del plan, acceder a un descuento en caso de que tenga un cupón y conocer los detalles que incluye el plan seleccionado." />
    </Helmet>

    <Row className="height-view">
    <Col className="background-left-choose" md={4}>
    <Row className="mt-5">
    <div className="offset-md-3">
    <a href={WEB_ROOT}>
    <Col md={5}><img src="../homegreen.png"  className="d-inline-block align-top" alt=""/></Col>
    </a>
    </div>
    </Row>

    <div className="offset-md-4 offset-1">
    <Row className="mt-5">
    <Col md={12}>
    <p className="text-init-choose">Plan escogido:</p>
    </Col>
    </Row>

    <Row className="height-button-choose mr-3">
    <div className="ml-3 background-plan-choose col-5">
    <div className="mt-2">
    <span className="float-left number-month-choose"> {(plan && plan.plan_name) ? plan.plan_name : "--"} </span>
    </div>

    </div>
    <center>
    <img className="" src="../line-gray.png" alt="separator"/>
    </center>

    <div className="background-plan-choose col-6">
    <div className="mt-2">
    <span className="value-plan-choose">US ${(plan && plan.subtotal) ? plan.subtotal : "--"}</span>
    </div>

    </div>
    </Row>

    <Row className="mt-5 mb-md-0 mb-4 mr-4">
    <Col md={12}>

    <div className="my-0 py-0 price-choose  w-100">
    <p className="my-0 py-0 sub-total-choose">Subtotal: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.subtotal) ? plan.subtotal : "--"}</span></p>
    </div>

    <div className="my-0 py-0 price-choose w-100">
    <p className="my-0 py-0 sub-total-choose">Descuento: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.discount) ? plan.discount : "0"}</span></p>
    </div>

    <p className="my-2 py-0 total-choose w-100">Total: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.total) ? plan.total : "--"}</span></p>
    </Col>
    </Row>


        <Form ref={c => { this.form = c }} onSubmit={this.handleForm.bind(this)}>
        <Row className="mt-5 menu-name">

          <Col md={12}>
            <label className="mt-1 name-almorza-menu">Tienes un cupón</label>

            <Input
            type="text"
            className="menu name-input-menu"
            name="name_order"
            placeholder="Código con descuento"
            value={this.state.cupon_code}
            onChange={this.onChangeCuponCode}
            validations={[required]}
            />

            {(plan && plan.code_status && plan.code_status === "error") ?
              <div className="texts-alerts alert-mw color-danger" role="alert">¡El cupon no es valido!</div> :
              null }

              {(plan && this.state.cupon_code !== "" && plan.code_status && plan.code_status === "ok") ?
                <div className="texts-alerts alert-mw color-success" role="alert">¡El cupon tiene descuento!</div> :
                null }


              <button type="submit" className="btn btn-green-steps-menu mb-5 mt-4">Aplicar
              </button>

          </Col>

          </Row>

        <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c }} />
        </Form>

    </div>




    </Col>

    <Col className="background-right-choose" md={8}>
    <div className="offset-md-1">

    <Row className="text-center mt-5">

    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-choose check-enable-choose" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-enable-choose">Plan escogido</label>
    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-choose check-disable-choose" icon="check-circle"/>
    <label className="d-block text-center control-label align-bottom text-disable-choose">Datos personales</label>

    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-choose check-disable-choose" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-disable-choose">Método de pago</label>


    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-choose check-disable-choose" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-disable-choose">Confirmación</label>
    </div>
    </Row>
    </div>

    <div className="offset-md-1">
    <Row className="mt-5">
    <Col md={3}>
    <h2 className="my-plan-choose">MI PLAN</h2>
    <p className="my-0 py-1 sub-plan-choose">Incluye</p>
    </Col>
    </Row>
    </div>

    <div className="offset-md-1">
    <Row>
    <Col md={4}>
    <p className="description-menu-choose">
    {(plan && plan.description) ? plan.description : ""}
    </p>

    </Col>
    <Col md={4}>
    <img src={(plan && plan.img) ? plan.img : ""}  className="img-resp-choose d-inline-block align-top" alt=""/>
    </Col>

    </Row>
    </div>

    <div className="offset-6">
    <Row className="mt-5">
    <Col md={8}>
    <Link to={'/buyer-data/' + this.props.match.params.id}>
    <button id="submit" value="Submit" type="submit" className="btn btn-green-steps-choose float-right">SIGUIENTE
    <img className="image ml-1" src="../after.png" alt="next"/>
    <img className="image ml-1" src="../after-green.png" alt=""/>
    </button>
    </Link>
    </Col>
    </Row>
    </div>

    <div className="offset-5">
    <Row className="mt-5">
    <Col md={12}>
    <p className="copyright-choose">ALMORZADIARIO © 2021</p>
    </Col>
    </Row>
    </div>

    </Col>
    </Row>
    </div>
  )
}
}

const mapDispatchToProps = dispatch => ({
  getPlanInView: (data) => {
    return dispatch(getPlan(data))
  },
  getPropetyInView: (value) => {
    return dispatch(getPropety(value))
  },
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  }
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  namePerson: (state.auth && state.auth.user) ? state.auth.user.name : ""
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlan);
