import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom"
import { MDBIcon } from "mdbreact";
import { connect } from 'react-redux';
import { logout } from "../../actions/auth";
import Navbar from '../../components/navbar-gray/navbarGray';
import { getDataUser } from "../../actions/order";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";

import { updateUser } from "../../actions/auth";

import {required, validEmail, lengthMinPhone, lengthMax, lengthMinDoc} from "../../core/validations";

import '../../components/dashboard-profile/profile.scss';
import 'font-awesome/css/font-awesome.min.css';

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: this.props.user.email ? this.props.getDataInView("email")  : "",
      name: this.props.user.name ? this.props.getDataInView("name") : "",
      lastName: this.props.user.last_name ? this.props.getDataInView("last_name") : "",
      document: this.props.user.document_number ? this.props.getDataInView("document_number")  : "",
      typeDocument: this.props.user.type_document ? this.props.getDataInView("type_document")  : "",
      phone: this.props.user.phone_number ? this.props.getDataInView("phone_number")  : "",
      password: this.props.user.password ? this.props.getDataInView("password")  : "",
      newPassword: '',
      error: '',
      errorNew:''
    };
    this.handleEvent = this.handleEvent.bind(this)
  }

  componentDidMount() {
    document.querySelector('.background-left').style = 'background-color: #E9E1D0'
    document.querySelector('.background-right').style = 'background-color: #F0ECE3'
    document.querySelector('.background-right-resp').style = 'background-color: #E9E1D0'

  }

  onChangeName = (e) => {
    this.setState({name: e.target.value});
  };

  onChangeLastName = (e) => {
    this.setState({lastName: e.target.value});
  };

  onChangePhone = (e) => {
    this.setState({phone: e.target.value});
  };

  onChangeEmail = (e) => {
    this.setState({email: e.target.value});
  };

  onChangeDocument = (e) => {
    this.setState({document: e.target.value});
  };

  onChangeTypeDocument = (e) => {
    this.setState({typeDocument: e.target.value});
  };

  onChangePassword = (e) =>{
    this.setState({password: e.target.value})
  }

  onChangeNewPassword = (e) =>{
    this.setState({newPassword: e.target.value})
  }

  handleForm = (e) => {
    e.preventDefault();

    this.setState({successful: false});

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {

      if (this.state.newPassword && !this.state.password) {
        this.setState({error: "¡Requerido!"});
      } else if(!this.state.newPassword && this.state.password){
        this.setState({errorNew: "¡Requerido!"});
      } else {
        this.setState({errorNew: "", error: ""});
        this.props.updateUserInView(this.state.name, this.state.lastName, this.state.email, this.state.phone, this.state.document, this.state.typeDocument, this.state.password, this.state.newPassword)
      .then((user) => {
        this.setState({successful: true});
      })
      .catch(() => {
        this.setState({successful: false});
      });
    }
    }
  };

  handleEvent(eventKey){
      if (eventKey === "1") {
        this.props.history.push('/create-order')
      }else if (eventKey === "2") {
        this.props.history.push('/profile')
      }else  {
        this.props.history.push('/')
      }
  }

  render() {

    return (

      <div>
      <section>
      <Navbar/>
      </section>
      <Row>
      <Col className="background-left-profile" md={4}>
      <Row className="mt-5 mb-5 mb-md-0">
      <div className="offset-md-4 offset-1">
      <Col md={12}>
      <h2 className="salute-profile">HOLA, { this.props.namePerson ? this.props.namePerson.toUpperCase() : "" }</h2>

      </Col>
      </div>
      </Row>

      <Row className="mt-5 d-none d-sm-none d-md-block">
      <div className="offset-md-4 offset-1">
      <Col md={12}>
      <div className="separator-profile">
      <Link to='/create-order' >
      <p className="dashboard-item-profile">Crear recetario</p>
      </Link>
      </div>
      <div className="separator-profile">
      <Link to='/profile'>
      <p className="active-pro dashboard-item-profile">Perfil</p>
      </Link>
      </div>
      <div className="separator-profile">
      <Link to='/'>
      <p className="dashboard-item-profile">Historial</p>
      </Link>
      </div>
      <p onClick={this.props.logOut} className="cursor-profile dashboard-item-profile">Salir</p>
      </Col>
      </div>
      </Row>

      </Col>

      <Col className="background-right-profile" md={8}>

      <Row className="mt-5">
      <div className="offset-md-1">
      <Col md={12}>
      <h2 className="salute-profile">MIS DATOS <MDBIcon className="ml-3 icon" icon="edit"/><span className="edit-text-profile"> Editar</span></h2>
      </Col>
      </div>
      </Row>

      <Form ref={c => { this.form = c }} onSubmit={this.handleForm.bind(this)}>
      <div className="offset-md-1">
      <Row className="mt-5">
      <Col md={10}>
      <label className="select-label-profile">Email</label>
      <Input
      type="email"
      className="form-control input-form input-h-profile"
      name="email"
      placeholder="Email"
      value={this.state.email}
      onChange={this.onChangeEmail}
      validations={[required, validEmail]}
      />

      </Col>
      </Row>
      </div>

      <div className="offset-md-1">
      <Row className="mt-3">
      <Col md={5}>
      <label className="select-label-profile">Nombres</label>
      <Input
      type="text"
      className="form-control input-form input-h-profile"
      name="name"
      value={this.state.name}
      placeholder="Nombres"
      onChange={this.onChangeName}
      validations={[required]}
      />
      </Col>
      <Col md={5}>
      <label className="select-label-profile">Apellidos</label>
      <Input
      type="text"
      className="form-control input-form input-h-profile"
      name="last_name"
      value={this.state.lastName}
      placeholder="Apellidos"
      onChange={this.onChangeLastName}
      validations={[required]}
      />

      </Col>
      </Row>
      </div>


      <div className="offset-md-1">
      <Row className="mt-3">
      <Col md={4}>
      <label className="select-label-profile">Número celular</label>

      <Input
      type="number"
      className="form-control input-form input-h-profile"
      name="phone"
      placeholder="Número celular"
      value={this.state.phone}
      onChange={this.onChangePhone}
      validations={[required, lengthMinPhone, lengthMax]}
      />

      </Col>
      <Col md={2}>
      <label className="select-label-profile">Documento</label>

      <Select name='document' onChange={this.onChangeTypeDocument} className="input-h-profile form-control input-form" value={this.state.typeDocument} validations={[required]}>
      <option value=''>Seleccione tipo de documento</option>
      <option value="1">Cedula de ciudadanía</option>
      <option value="2">Cedula de extranjera</option>
      <option value="3">Tarjeta de identidad</option>
      </Select>

      </Col>
      <Col md={4}>

      <label className="select-label-profile">Número</label>
      <Input
      type="number"
      className="form-control input-form input-h-profile"
      name="document"
      placeholder="Número"
      value={this.state.document}
      onChange={this.onChangeDocument}
      validations={[required, lengthMinDoc, lengthMax]}
      />
      </Col>
      </Row>
      </div>


      <div className="offset-md-1">
      <Row className="mt-3">
      <Col md={5}>
      <label className="select-label-profile">Contraseña actual</label>
      <Input
      type="password"
      className="form-control input-form input-h-profile"
      name="password"
      placeholder="Contraseña actual"
      onChange={this.onChangePassword}
      />
      <div className="alert-mw texts-alerts color-danger" role="alert">
        {this.state.error}
      </div>
      </Col>

      <Col md={5}>
      <label className="select-label-profile">Contraseña nueva</label>
      <Input
      type="password"
      className="form-control input-form input-h-profile"
      name="new_password"
      placeholder="Contraseña nueva"
      onChange={this.onChangeNewPassword}
      />
      <div className="alert-mw texts-alerts color-danger" role="alert">
        {this.state.errorNew}
      </div>

      </Col>
      </Row>
      </div>

      <div className="offset-1">
        <Row className="mt-8">
          {this.props.message && (
            <div className="form-group">
            <div className={ this.state.successful ? "texts-alerts alert color-success" : "texts-alerts alert color-danger" } role="alert">
            { this.props.message }
            </div>
            </div>
          )}
        </Row>
      </div>

      <div className="offset-7">
      <Row className="mt-4">
      <Col md={8}>
      <button id="submit" value="Submit" type="submit" className="btn btn-green-steps-profile float-right">EDITAR DATOS PERSONALES</button>
      </Col>
      </Row>
      </div>
      <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c }} />
      </Form>

      <div className="offset-md-5 offset-3">
      <Row className="mt-5">
      <Col md={4}>
      <p className="copyright-profile">ALMORZADIARIO © 2021</p>
      </Col>
      </Row>
      </div>

      </Col>
      </Row>

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserInView: (name, lastName, email, phone, document, typeDocument, password, newPassword) => {
    return dispatch(updateUser(name, lastName, email, phone, document, typeDocument, password, newPassword))
  },
  logOut: () => {
    return dispatch(logout())
  },
  getDataInView: (value) => {
    return dispatch(getDataUser(value))
  },
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  namePerson: (state.auth && state.auth.user) ? state.auth.user.name : "",
  user: (state.auth && state.auth.user) ? state.auth.user : {},
  message: state.message ? state.message.message : ""
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
