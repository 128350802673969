import {
  SET_ITEM_ORDER,
  GET_ITEM_ORDER,
  GET_USER_DATA,
  GET_HISTORY,
  GET_PDF,
  GET_ITEMS_FORM_CHECKOUT,
  GET_LAST_INFO_BUYER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  ADD_ITEMS_TO_ORDER_SUCCESS,
  ADD_ITEMS_TO_ORDER_FAIL,
  SET_MESSAGE,
  CLEAR_MESSAGE,
  GET_DATA,
  GET_COUNT_RECIPES
} from "./types";

import OrderService from "../services/order.service";

export const setPropety = (value, property) => (dispatch) => {

  OrderService.setPropety(value, property);

  dispatch({
    type: SET_ITEM_ORDER,
   });
};

export const getPropety = (property) => (dispatch) => {
  dispatch({
    type: GET_ITEM_ORDER,
   });

  return OrderService.getPropety(property);
};

export const getDataUser = (property) => (dispatch) => {
  dispatch({
    type: GET_USER_DATA,
   });

  return OrderService.getDataUser(property);
};


export const getHistory = () => (dispatch) => {
  dispatch({
    type: GET_HISTORY,
   });

  return OrderService.getHistory();
};

export const getPDF = (data) => (dispatch) => {
  dispatch({
    type: GET_PDF,
   });

  return OrderService.getPDF(data);
};

export const getItemsWebCheckout = (data) => (dispatch) => {
  dispatch({
    type: GET_ITEMS_FORM_CHECKOUT,
   });

  return OrderService.getItemsWebCheckout(data);
};

export const addOrder = (name, lastName, email, phone, document, typeDocument) => (dispatch) => {
  let data = { buyed_name: name,
               buyed_lastname: lastName,
               buyed_email: email,
               buyed_phone_number: phone,
               buyed_document_number: document,
               buyed_document_type_document: typeDocument,
               ...OrderService.getOrder()
             }

  dispatch({
    type: CLEAR_MESSAGE,
    payload: "message",
  });

  return OrderService.addOrder(data).then(
    (response) => {
      dispatch({
        type: ADD_ORDER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "¡La orden ha sido creada!, cargando medios de pago ...",
      });

      return Promise.resolve(response);
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        let status = -1

        if (error.status) {
          status = error.status
        } else if (error.data && error.data.status) {
          status = error.data.status
        } else if (error.response && error.response.data && error.response.data.status) {
          status = error.response.data.status
        }

      if (status && status === 400) {
          message = "Hubo un error inesperado algunos datos no se guardaron."
      } else if (message === "") {
          message = "Hubo un error inesperado."
      }

      dispatch({
        type: ADD_ORDER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const addRecipesToOrder = (id_order, month, items) => (dispatch) => {
  let data = { id_order: id_order,
               month: month,
               items: items
  }

  dispatch({
    type: CLEAR_MESSAGE,
    payload: "message",
  });

  return OrderService.addRecipesToOrder(data).then(
    (response) => {
      dispatch({
        type: ADD_ITEMS_TO_ORDER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "¡Se ha agregado los platos!",
      });

      return Promise.resolve(response);
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        let status = -1

        if (error.status) {
          status = error.status
        } else if (error.data && error.data.status) {
          status = error.data.status
        } else if (error.response && error.response.data && error.response.data.status) {
          status = error.response.data.status
        }

      if (status && status === 400) {
          message = "Hubo un error inesperado algunos datos no se guardaron."
      } else if (message === "") {
          message = "Hubo un error inesperado."
      }

      dispatch({
        type: ADD_ITEMS_TO_ORDER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getLastBuyerInfo = () => (dispatch) => {
  dispatch({
    type: GET_LAST_INFO_BUYER,
   });

  return OrderService.getLastBuyerInfo();
};

export const getCountRecipes = (id_order, month) => (dispatch) => {
  let data = { id_order: id_order,
               month: month,
  }

  dispatch({
    type: GET_COUNT_RECIPES,
   });

  return OrderService.getCountRecipes(data);
};

export const getData = (data) => (dispatch) => {
  dispatch({
    type: GET_DATA,
   });

  return OrderService.getData(data);
};

export const removeInternalOrder = () => (dispatch) => {
  OrderService.removeOrder();
};
