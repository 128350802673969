import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom"
import { MDBIcon } from "mdbreact";
import 'font-awesome/css/font-awesome.min.css';
import '../../components/confirmed-order/confirmedOrder.scss';
import { connect } from "react-redux";
import { getPlan } from "../../actions/plan";
import { getData } from "../../actions/order";

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;

class ConfirmedOrder extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
      plan: null,
      error: null,
      referenceCode: 0,
      name: '',
      transactionState:'',
      responseTransaction:'',
      value: 0,
      buyerEmail:'',
      paymentMethod:'',

    };
  }

  componentDidMount() {
    this.fetchPlan();
    this.getParamsPayU();
  }

  getParamsPayU(){
    const query = new URLSearchParams(this.props.location.search);
    let referenceCode = query.get('referenceCode')
    let transactionState   = query.get('transactionState')
    const value   = query.get('TX_VALUE')
    let buyerEmail   = query.get('buyerEmail')
    let paymentMethod   = query.get('lapPaymentMethod')

    let message = '';
    let response = '';

    if (transactionState === '4' ) {
    	message = "Transacción aprobada";
      response = "¡Tu Almorzadiario ya está listo! Haz clic en Descargar para ver tu PDF";
    }

    else if (transactionState === '6' ) {
    	message = "Transacción rechazada";
      response = "¡Su pedido ha sido rechazado!";

    }

    else if (transactionState === '104' ) {
    	message = "Error";
      response = "Hubo un error en la solicitud";

    }

    else if (transactionState === '7' ) {
    	message = "Transacción pendiente";
      response = "¡Su pedido se encuentra pendiente!";
    }

    else {
    	message= 'Aún no ha realizado la transacción';
    }

    this.setState({ referenceCode: referenceCode,
    transactionState: message, responseTransaction: response,
    value: value, buyerEmail: buyerEmail, paymentMethod: paymentMethod})

    this.fetchData(referenceCode)
  }

  fetchPlan() {
    this.setState({ isLoading: true})

    if (!this.props.match || !this.props.match.params ||  !this.props.match.params.id) {
      return
    }

    this.props.getPlanInView({id: this.props.match.params.id})
    .then(data => {
      this.setState({
        plan: data,
        isLoading: false,
      })
    }
  )
  .catch(error => this.setState({ error, isLoading: false }));
}

fetchData(id) {
  this.setState({ isLoading: true})

  let data = {id}

  this.props.getDataInView(data)
  .then(data => {
    this.setState({
      data: data,
      plan: data.plan ? data.plan : {},
      isLoading: false,
    })
  },

)
.catch(error => this.setState({ error, isLoading: false }));
}


render() {
  let data = this.state.data
  let plan = this.state.plan

  return (

    <div>
    <Row className="height-view">
    <Col className="background-left-conf" md={4}>
    <Row className="mt-5">
    <div className="offset-md-3">
    <a href={WEB_ROOT}>
    <Col md={5}><img src="../homegreen.png"  className="d-inline-block align-top" alt=""/></Col>
    </a>
    </div>
    </Row>
    <div className="offset-md-4 offset-1">

    <Row className="mt-5">
      <Col md={12}>
        <h2 className="my-plan-conf">RESUMEN DE COMPRA</h2>
        <img src={(plan && plan.img) ? plan.img : ""}  className="w-100 mt-3 d-inline-block align-top" alt=""/>
      </Col>
    </Row>

    <Row className="mt-5">
      <Col md={12}>
        <p className="text-init-conf">Plan escogido:</p>
      </Col>
    </Row>

  <Row className="height-button-conf">

    <div className="ml-3 background-plan-conf col-5">
      <div className="mt-2">
        <span className="float-left number-month-conf"> {(plan && plan.plan_name) ? plan.plan_name : "--"} </span>
      </div>
    </div>

    <center>
      <img className="" src="../line-gray.png" alt="separator"/>
    </center>

    <div className="background-plan-conf col-6">
      <div className="mt-2">
        <span className="value-plan-conf">US ${(data && data.price) ? data.price : "--"}</span>
      </div>
    </div>

  </Row>

    <Row className="mt-5 mb-md-0 mb-4">
      <Col md={12}>
        <div className="my-0 py-0 price-conf">
          <p className="my-0 py-0 sub-total-conf">Subtotal: <span className="value-plan-conf float-lg-right">US ${(data && data.price) ? data.price : "--"}</span></p>
        </div>

        <div className="my-0 py-0 price-conf w-100">
          <p className="my-0 py-0 sub-total-conf">Descuento: <span className="value-plan-conf float-lg-right"> US ${(data && data.discount) ? data.discount : "0"}</span></p>
        </div>

          <p className="my-0 mb-5 py-0 total-conf">Total: <span className="value-plan-conf float-lg-right">US ${(data && data.price) ? data.price : "--"}</span></p>
      </Col>
    </Row>



    </div>


    </Col>

    <Col className="background-right-conf" md={8}>
    <div className="offset-md-1">

    <Row className="text-center mt-5">

    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-conf check-enable-conf" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-enable-conf">Plan escogido</label>
    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-conf check-enable-conf" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-enable-conf">Datos personales</label>

    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-conf check-enable-conf" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-enable-conf">Método de pago</label>


    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-conf check-enable-conf" icon="check-circle"/>
    <label className="d-block  text-center control-label align-bottom text-enable-conf">Confirmación</label>
    </div>
    </Row>
    </div>

    <div className="offset-md-1">
    <Row className="mt-5">
    <Col md={12}>
    <h2 className="my-plan-conf">{this.state.transactionState}</h2>
    </Col>
    </Row>
    </div>

    <div className="offset-md-1">
    <Row>
    <Col className="ml-3 mr-3 mt-4 background-confirmed" md={9}>
    <div className="space-conf">
    <p className="py-0 my-0 order-conf">{this.state.responseTransaction}</p>
    </div>
    </Col>

    </Row>
    </div>

    <div className="offset-md-1">

    <Row className="mt-4">

    <Col md={3}>
    <p className="my-0 py-0 history-conf">Detalles de la compra</p>
    <p className="my-0 py-0 history-desc-conf">Pedido #: {this.state.referenceCode} </p>
    </Col>

    <Col md={3}>
    <p className="my-0 py-0 history-conf">Medio de pago</p>
    <p className="my-0 py-0 history-desc-conf">{this.state.paymentMethod}
    <span className="ml-5">US {this.state.value}</span>
    </p>
    </Col>

    <Col md={4}>
    <p className="my-0 py-0 history-conf">Comprador</p>
    <p className="my-0 py-0 history-desc-conf">Datos personales</p>
    <p className="my-0 py-0 history-desc-conf mt-3">Email:
    <span className="ml-1">{this.state.buyerEmail}</span></p>
    </Col>

    </Row>
    </div>



    <Link to={'/?order=' + this.state.referenceCode}>
    <div className="offset-md-7 mt-5">
    <button className="btn btn-green-steps-buyer">Descargar
    <img className="image  arrow" src="../after.png" alt="next"/>
    <img className="image arrow" src="../after-green.png" alt=""/>
    </button>
    </div>
    </Link>


    <div className="offset-4">
    <Row className="mt-5">
    <Col md={12}>
    <p className="copyright-conf">ALMORZADIARIO © 2021</p>
    </Col>
    </Row>
    </div>

    </Col>
    </Row>
    </div>
  )
}
}

const mapDispatchToProps = dispatch => ({
  getPlanInView: (data) => {
    return dispatch(getPlan(data))
  },

  getDataInView: (data) =>{
    return dispatch(getData(data))
  },
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  namePerson: (state.auth && state.auth.user) ? state.auth.user.name : ""
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedOrder);
