import OrderService from "../services/order.service";

const stepsRoutes = {
      "1": "create-order",
      "2": "feeding",
      "3": "amount",
      "4": "plan",
      "5": "choose-menu",
      "6": "choose-menu",
      "7": "choose-menu",
}
const stepsField = {
      "1": "name_user",
      "2": "id_diet",
      "3": "people_count",
      "4": "id_plan",
      "5": "items",
      "6": "items",
      "7": "items",
}

export const checkBeforeStep = (currentStep) => {
    let isFillBeforeStep = getRoute

    let backStep = stepsField[currentStep - 1] // currentStep needs back step
    let value = OrderService.getPropety(backStep)

    if (value === null || value === "" || value === 0 || value === []) {
      return false;
    }

    if (backStep === "items" && currentStep >= 6 && value.length !== 24) {
      return false;
    }

    return isFillBeforeStep
};

const getRoute = (currentStep) => {
  return stepsRoutes[currentStep]
};

export const goBackStep = (currentStep) => {
  if (typeof window !== 'undefined') {
       window.location = window.location.protocol + '//' + window.location.host + '/#/'+ getRoute(currentStep - 1);
  }
}
