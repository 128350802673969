import React, { useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";

import moment from 'moment';

import {withRouter} from 'react-router-dom';
import {required} from "../../core/validations";

import "../../core/sass/forms.scss";

const Downloads = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [month, setMonth] = useState("");

  const { message } = useSelector(state => state.message);
  const [loading, setLoading] = useState(false);

  const handlePDF = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      props.handleModalOpen()
      props.downloadPDF(props.id_order, month)
    }
  }

  const onChangeMonth = (e) => {
    const value = e.target.value;
    setMonth(value);
  };

  const options = []
  for (var i = 1; i <= props.currentMonth; i++) {
    options.push(i);
  }

  let addOtherMonth = false
  let addOtherMonthDate = false

  if (props.currentMonth < props.count_monts ) {
    addOtherMonth = true
  }

  let nextMonth = props.currentMonth + 1
  let dateToCreatedNext = null
  if (addOtherMonth && props.order_date) {
    dateToCreatedNext = moment(props.order_date).add(props.currentMonth, 'M').subtract(7, "days");
    let now = moment()

    if (dateToCreatedNext <= now) {
      addOtherMonthDate = true
    }
  }

  const nextMonthClick = (e) => {
    if (!addOtherMonthDate) {
      e.preventDefault()
      let message = "Solo se puede armar el menú del siguiente mes una semana antes de terminar el mes del plan en curso."

      if (dateToCreatedNext) {
        message += " \n Fecha en que se habilita " + dateToCreatedNext.format("YYYY-MM-DD")
      }

      props.changeMessageNextMonth(message)
    }
  }

  return (
    <>
    <Modal  className="modal-background" size="sm" md="6" show={props.modalOpen} onHide={props.handleModalOpen}>

    <Modal.Header  closeButton class="with-100">
    <Modal.Title className='text-center w-100 ml-3'>¡DESCARGAR TU ALMORZADIARIO!</Modal.Title>
    </Modal.Header>
    <Modal.Body className="form-background">
    <Form className="pt-0" onSubmit={handlePDF} ref={form}>
    <label className="select-label" for="Name">Mes a descargar</label>

    <Select name="name" className="mb-3 form-control input-form"
    onChange={onChangeMonth}
    validations={[required]}>
    <option value=""> Seleccione el mes </option>
    {options.map((option) => (
      <option value={option}>{option}</option>
    ))}
    </Select>

    <p className="mb-3 text-center text-download-pdf">Podra descargar un documento PDF con las recetas del plan.</p>


    <div className="text-center" disabled={loading}>
    <button type="submit" className="btn btn-green">DESCARGAR  </button>
    </div>

    {message && (
      <div className="form-group">
      <div className="texts-alerts alert alert-danger" role="alert">
      {message}
      </div>
      </div>
    )}

    { addOtherMonth ?
      <div className="text-center pb-3">
        <hr className="mt-3"/>
        <p className="mb-3 text-center text-download-pdf">Agrega las recetas del siguiente mes.</p>
        <Link  onClick={(e) => nextMonthClick(e)} to={"choose-menu/order/"+ props.id_order +"/month/" + (nextMonth)+"/diet/" + props.id_diet} className="btn btn-white space-top"> Crear recetario Mes { props.currentMonth + 1} </Link>
      </div>
    :  <br/> }

    {props.messageNext && (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        {props.messageNext}
      </div>
    )}

    <CheckButton style={{ display: "none" }} ref={checkBtn} />
    </Form>

    </Modal.Body>

    </Modal>
    </>
  );
}

export default withRouter(Downloads)
