import React from 'react';
import Row from 'react-bootstrap/Row';
import Navbar from '../../components/navbar/navbar';
import {Animated} from "react-animated-css";
import '../../components/step-name/stepName.scss';
import { connect } from "react-redux";
import { setPropety, getPropety } from "../../actions/order";
import { Helmet } from 'react-helmet';

class StepName extends React.Component{

  componentDidMount() {
    document.querySelector('.background-left').style = 'background-color: #F4B2B6'
    document.querySelector('.background-right').style = 'background-color: #FDDD78'
    document.querySelector('.background-right-resp').style = 'background-color: #F4B2B6'

    // value in input name is equals to user logged when return or first time, if namePerson is not empty add name into order
    if (this.props.getPropetyInView("name_user") === null || this.props.getPropetyInView("name_user")) {
      if (this.props.namePerson !== "") {
        this.props.setPropetyInView(this.props.namePerson, "name_user")
      }
    }
  }

  constructor(props){
    super(props);

    this.state = {
      name: this.props.getPropetyInView("name_user"),
    }

  }


  handleValidation() {
    if (this.props.setPropetyInView) {
      this.props.setPropetyInView(this.state.name ? this.state.name : this.props.namePerson, "name_user")
      this.props.history.push('/feeding')
    }
  }

  contactSubmit(e){
    e.preventDefault();
    this.handleValidation();
  }

  handleChange = (e) => {
    this.setState({name: e.target.value});
  };

  render(){


    return (




      <div>
      <Helmet>
          <title>cómo te llamas</title>
          <meta name="description" content="Aquí comienza el proceso de planificador. Le preguntamos al usuario su nombre" />
      </Helmet>
      <section>
      <Navbar/>
      </section>

      <div>
      <Row className="height-view">
        <div className="background-left-name col-md-6">
          <div className="offset-md-2 mb-5">
            <div className="col-md-10 mt-5">
              <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={0} isVisible={true}>
                <h2 className="mt-5 text-init-name">ANTES DE COMENZAR</h2>
              </Animated>
              <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={1000} isVisible={true}>
                <h2 className="text-subinit-name">CUÉNTANOS CÓMO TE LLAMAS</h2>
              </Animated>
            </div>
          </div>
        </div>



      <div className="background-right-name col-md-6">
      <form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)} >

      <fieldset>
        <div className="offset-md-2 offset-1">

          <div className="row">

            <div className="col-md-5 mt-5">
              <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
                <input name="input-name" placeholder="Ej. Cata" type="text" className="name mt-3 borde-name" onChange={this.handleChange} value={this.state.name ? this.state.name : this.props.namePerson } required/>
                <label className="mt-1 control-label-name align-bottom" >Ingresa tu nombre aquí</label>
              </Animated>
            </div>

            <div className="col-md-4 mt-md-5 mt-0">
            <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
                <button id="submit" value="Submit" type="submit" className="btn btn-green-steps-name">SIGUIENTE
                  <img className="image ml-1" src="../after.png" alt="next"/>
                  <img className="image ml-1" src="../after-green.png" alt=""/>
                </button>
              </Animated>
            </div>

          </div>
        </div>




            <div className="offset-md-2">
              <div className="col-md-4">
              <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
                <img className="img-resp" src="../table.png" alt=""/>
              </Animated>
              </div>
          </div>

      </fieldset>
      </form>

      </div>
      </Row>

      </div>

      </div>


    )
  }
}


const mapDispatchToProps = dispatch => ({
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  },
  getPropetyInView: (property) => {
    return dispatch(getPropety(property))
  }
});

const mapStateToProps = state => ({
  namePerson: (state.auth && state.auth.user) ? state.auth.user.name : ""
});


export default connect(mapStateToProps, mapDispatchToProps)(StepName);
