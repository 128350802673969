import React, { useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import {withRouter} from 'react-router-dom';
import { login } from "../../actions/auth";
import { required } from "../../core/validations";

import "../../core/sass/forms.scss";

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          if (props.orderInProgress && props.orderInProgress === true) {
            props.handleModalOpen()
            props.saveOrder()
          } else {
            props.history.push("/");
            window.location.reload();
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  let showLogin = false;

  if (!isLoggedIn && (
      props.location.pathname === "/profile" ||
      props.location.pathname === "/" ||
      props.location.pathname === "/choose-plan" )) {
    showLogin = true
  }

  return (
    <>
    <Modal className="modal-background" size="sm" md="6" show={ showLogin || (props.modalOpen && !isLoggedIn)} onHide={props.handleModalOpen}>
      <Modal.Header  closeButton className="with-100">

        <Modal.Title className='text-center w-100 ml-4'>
          ¡BIENVENIDO!
        </Modal.Title>

      </Modal.Header>
      <Modal.Body className="form-background">
        <Form onSubmit={handleLogin} ref={form}>
          <Input
            type="email"
            className="form-control input-form"
            name="username"
            placeholder = "Email"
            value={username}
            onChange={onChangeUsername}
            validations={[required]}
          />

          <br />

          <Input
            type="password"
            className="form-control input-form"
            name="password"
            placeholder="Contraseña"
            value={password}
            onChange={onChangePassword}
            validations={[required]}
          />

          <p className="text-right mb-5">
          <span className="links link-forget" onClick={props.handleChangeModalPassword}> ¿Olvidó contraseña?</span>
          </p>

          <div className="text-center mt-6" disabled={loading}>
            <button type="submit" className={loading ? "btn btn-green-loading" : "btn btn-green"}><img src="../loading.gif" className={loading ? "d-inline" : "d-none"} alt="loading" />{loading ? "CARGANDO" : "INGRESAR"}</button>
          </div>

          {message && (
              <div className="texts-alerts text-center my-3 color-danger" role="alert">
                {message}
            </div>
          )}

          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>

      </Modal.Body>
      <p className="text-center text-account">No tengo una cuenta,
        <span className="links" onClick={props.handleChangeModal}> Registrate</span>
      </p>
    </Modal>
    </>
);
}

export default withRouter(Login)
