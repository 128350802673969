export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const PASSWORD_SUCCESS = "PASSWORD_SUCCESS"
export const PASSWORD_FAIL = "PASSWORD_FAIL"

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_ITEM_ORDER = "SET_ITEM_ORDER";
export const GET_ITEM_ORDER = "GET_ITEM_ORDER";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_HISTORY = "GET_HISTORY";
export const GET_PDF = "GET_PDF";
export const GET_ITEMS_FORM_CHECKOUT = "GET_ITEMS_FORM_CHECKOUT";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const GET_LAST_INFO_BUYER = "GET_LAST_INFO_BUYER";
export const GET_COUNT_RECIPES = "GET_COUNT_RECIPES";
export const GET_DATA = "GET_DATA";
export const ADD_ITEMS_TO_ORDER_SUCCESS = "ADD_ITEMS_TO_ORDER_SUCCESS";
export const ADD_ITEMS_TO_ORDER_FAIL = "ADD_ITEMS_TO_ORDER_FAIL";

export const GET_PLAN = "GET_PLAN";
export const GET_PLANS = "GET_PLANS";
