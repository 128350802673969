import React, { useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import WebCheckOut from '../../components/webcheckout/webcheckout';
import '../../components/menu/menu.scss';

const PayOrden = (props) => {

  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePayOrden = (e) => {
    e.preventDefault();
    setLoading(true);

    setActive(true);
  }

  return (
    <>
    <Modal className="modal-background" size="sm" md="6" show={props.modalOpen} onHide={props.handleModalOpen}>
    <Modal.Header  closeButton className="with-100">

    <Modal.Title className='text-center w-100'>PAGAR ORDEN</Modal.Title>

    </Modal.Header>
      <Modal.Body className="form-background">

        <div className="text-center mt-6">
          <button onClick={handlePayOrden} type="submit" className={loading ? "btn btn-green-loading" : "btn btn-green"}><img src="../loading.gif" className={loading ? "d-inline" : "d-none"} alt="loading" />{loading ? "CARGANDO" : "CONTINUAR"}</button>
        </div>

      </Modal.Body>
    </Modal>
    {
      active &&
        <WebCheckOut id_order={props.id_order}/>
    }

    </>



  );
}

export default PayOrden;
