import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import './image.scss'
import Image from './image'

class ImagePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      picked: Map()
    }
    this.handleImageClick = this.handleImageClick.bind(this)
    this.renderImage = this.renderImage.bind(this)
  }

  getKey(val) {
    const { current_week } = this.props
    return val + "_" + current_week
  }

  handleImageClick(image) {
    const { onPick, max_select, total_added } = this.props
    const pickedImage = this.state.picked

    let newerPickedImage = null
    let key = this.getKey(image.value)

    if (pickedImage.has(key)) {
      newerPickedImage = pickedImage.delete(key)
    } else if (total_added < max_select) {
      newerPickedImage = pickedImage.set(key, image.src)
    }

    if (newerPickedImage) {
      this.setState({ picked: newerPickedImage })
    }

    onPick()
  }

  renderImage(image, i) {
    const { current_week} = this.props
    let isSelected = false

    let key = this.getKey(image.value)

    isSelected = this.state.picked.has(key)

    let recipe = { week: current_week, id_recipe: image.value}

    if (!isSelected && _.some(this.props.selectedImages, recipe)) {
      isSelected = true

      let newerPickedImage = this.state.picked.set(key, image.src)

      if (newerPickedImage) {
        this.setState({ picked: newerPickedImage })
      }
    }

    return (
      <Image
        src={image.src}
        isSelected={isSelected}
        onImageClick={() => this.handleImageClick(image)}
        current_week= {current_week}
        key={i}
      />
    )
  }

  render() {
    const { images } = this.props
    return (
      <div className="image_picker">
        { images.map(this.renderImage) }
        <div className="clear"/>
      </div>
    )
  }
}

ImagePicker.propTypes = {
  images: PropTypes.array,
  multiple: PropTypes.bool,
  onPick: PropTypes.func,
  max_select: PropTypes.number,
  total_added: PropTypes.number,
  current_week: PropTypes.number,
  selectedImages: PropTypes.array
}

export default ImagePicker
