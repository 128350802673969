import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { MDBIcon } from "mdbreact";
import LoginModal from '../../components/login/login';
import SingUpModal from '../../components/sign-up/signUp';
import WebCheckOut from '../../components/webcheckout/webcheckout';
import PasswordModal from '../../components/forgot-password/ForgotPassword';
import 'font-awesome/css/font-awesome.min.css';
import '../../components/buyer-data/buyerData.scss';
import { connect } from "react-redux";
import {compose} from "redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { withRouter } from 'react-router';
import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"
import { addOrder, getLastBuyerInfo, removeInternalOrder } from "../../actions/order";
import {required, validEmail, lengthMinPhone, lengthMax, lengthMinDoc} from "../../core/validations";
import { getPropety, setPropety } from "../../actions/order";
import { getPlan } from "../../actions/plan";
import { Helmet } from 'react-helmet';

import { clearMessage } from "../../actions/message";

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;

class BuyerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      plan: null,
      error: null,
      email: "",
      name: "",
      lastName: "",
      document: "",
      typeDocument: "",
      phone: "",
      successful: false,
      loginModal: false,
      signupModal: true,
      passwordModal: false,
      modalOpen: false,
      modalOpenMenu: false,
      modalOpenPdf: false,
      activePay: false,
      id_order: 0,
      handled_form: false,
      user_recommendation: {},
      cupon_code: this.props.getPropetyInView("code")
    };

    this.saveOrder = this.saveOrder.bind(this);

    // return to before step if is not fill in
    let currentStep = 7
    if (!checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }

  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.fetchLastBuyerInfo();
    }

    this.fetchPlan();
  }

  handleModalOpen = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return {
        message: "",
        modalOpen: !prevState.modalOpen
      }
    })
  }

  handleOpenMenu  = () => {
    this.setState((prevState) => {
      return{
        modalOpenMenu: !prevState.modalOpenMenu
      }
    })
  }

  handleChangeModal = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return {
        message: "",
        loginModal: !prevState.loginModal,
        signupModal: !prevState.signupModal,
        modalOpen: true,
      }
    })
  }

  handleChangeModalPassword = () => {
    this.props.clearMessage()
    this.setState((prevState) => {
      return{
        message: "",
        loginModal: !prevState.loginModal,
        passwordModal: !prevState.passwordModal,
        modalOpen: true,
      }
    })
  }

  fetchPlan() {
    this.setState({ isLoading: true})

    if (!this.props.match || !this.props.match.params ||  !this.props.match.params.id) {
      return
    }

    let data = {id: this.props.match.params.id}

    if (this.state.cupon_code !== "" || this.state.cupon_code !== null) {
      data["code"] = this.state.cupon_code
    }

    this.props.getPlanInView(data)
    .then(data => {
      this.setState({
        plan: data,
        isLoading: false,
      })
    }
  )
  .catch(error => this.setState({ error, isLoading: false }));
}

fetchLastBuyerInfo() {
  this.setState({ isLoading: true})

  this.props.getLastBuyerInfoInView()
  .then(data => {

    let buyed_info = {}

    if (data.hasOwnProperty("buyed_name") && this.state.name === "") {
      buyed_info["name"] = data.buyed_name
    }

    if (data.hasOwnProperty("buyed_lastname") && this.state.lastName === "") {
      buyed_info["lastName"] = data.buyed_lastname
    }

    if (data.hasOwnProperty("buyed_email") && this.state.email === "") {
      buyed_info["email"] = data.buyed_email
    }

    if (data.hasOwnProperty("buyed_phone_number") && this.state.phone === "") {
      buyed_info["phone"] = data.buyed_phone_number
    }

    if (data.hasOwnProperty("buyed_document_type_document") && this.state.typeDocument === "") {
      buyed_info["typeDocument"] = data.buyed_document_type_document
    }

    if (data.hasOwnProperty("buyed_document_number") && this.state.document === "") {
      buyed_info["document"] = data.buyed_document_number
    }

    // change inputs values
    this.setState({...buyed_info})
  }
)
.catch(error => console.log("Fail request"));
}

onChangeName = (e) => {
  this.setState({name: e.target.value});
};

onChangeLastName = (e) => {
  this.setState({lastName: e.target.value});
};

onChangePhone = (e) => {
  this.setState({phone: e.target.value});
};

onChangeEmail = (e) => {
  this.setState({email: e.target.value});
};

onChangeDocument = (e) => {
  this.setState({document: e.target.value});
};

onChangeTypeDocument = (e) => {
  this.setState({typeDocument: e.target.value});
};

handleForm = (e) => {
  e.preventDefault();

  this.setState({successful: false});

  this.form.validateAll();

  if (this.checkBtn.context._errors.length === 0) {

    if (!this.props.isLoggedIn) {
      this.setState({modalOpen: true})

      let user_order = { name: this.state.name,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone
      }

      this.setState({user_recommendation: user_order})
      this.props.setPropetyInView(user_order, "user_recommendation")
      return;
    }

    this.setState({handled_form: true})

    this.saveOrder()
  }
};

saveOrder() {
  this.props.addOrderInView(this.state.name,
                            this.state.lastName,
                            this.state.email,
                            this.state.phone,
                            this.state.document,
                            this.state.typeDocument)
  .then((order) => {

    this.setState({ successful: true });

    if (!order || order["id"] == null) {
      return;
    }

    if (order["state_pol"] === "4") {
      this.props.history.push('/')
    } else {
      this.setState({id_order: order["id"], activePay: true});
    }

    this.props.removeOrderInView()
  })
  .catch(() => {
    this.setState({successful: false});
  });
}


render() {
  let plan = this.state.plan

  return (

    <div>
    <Helmet>
        <title>datos del comprador</title>
        <meta name="description" content="Aquí el usuario ingresa sus datos para iniciar el proceso de compra y encuentra un resumen de su orden." />
    </Helmet>
    <Row className="height-view">
    <Col className="background-left-buyer" md={4}>
    <Row className="mt-5">
    <div className="offset-md-3">
    <a href={WEB_ROOT}>
    <Col md={5}><img src="../homegreen.png"  className="d-inline-block align-top" alt=""/></Col>
    </a>
    </div>
    </Row>

    <div className="offset-md-4 offset-1">
    <Row className="mt-5 mr-3">
    <Col md={12}>
    <h2 className="my-plan-buyer">RESUMEN DE COMPRA</h2>
    <img src={(plan && plan.img) ? plan.img : ""}  className="w-100 mt-3 d-inline-block align-top" alt=""/>
    </Col>
    </Row>

    <Row className="mt-5">
    <Col md={12}>
    <p className="text-init-buyer">Plan escogido:</p>
    </Col>
    </Row>

    <Row className="mr-3 height-button-buyer">
    <div className="ml-3 background-plan-buyer col-5">
    <div className="mt-2">
    <span className="float-left number-month-buyer"> {(plan && plan.plan_name) ? plan.plan_name : "--"} </span>
    </div>

    </div>

    <center>
    <img className="" src="../line-gray.png" alt="separator"/>
    </center>

    <div className="background-plan-buyer col-6">
    <div className="mt-2">
    <span className="value-plan-buyer">US ${(plan && plan.subtotal) ? plan.subtotal : "--"}</span>
    </div>

    </div>
    </Row>

    <Row className="mt-5 mb-md-0 mb-4 mr-4">
      <Col md={12}>

        <div className="my-0 py-0 price-choose  w-100">
          <p className="my-0 py-0 sub-total-choose">Subtotal: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.subtotal) ? plan.subtotal : "--"}</span></p>
        </div>

        <div className="my-0 py-0 price-choose w-100">
          <p className="my-0 py-0 sub-total-choose">Descuento: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.discount) ? plan.discount : "0"}</span></p>
        </div>

          <p className="mt-2 mb-5 py-0 total-choose w-100">Total: <span className="value-plan-choose float-lg-right"> US ${(plan && plan.total) ? plan.total : "--"}</span></p>
      </Col>

    </Row>
    </div>

    </Col>

    <Col className="background-right-buyer" md={8}>

    <div className="offset-md-1">
    <Row className="text-center mt-5">
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-buyer check-enable-buyer" icon="check-circle"/>
    <label className="d-block text-center control-label align-bottom text-enable-buyer">Plan escogido</label>
    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-buyer check-enable-buyer" icon="check-circle"/>
    <label className="d-block text-center control-label align-bottom text-enable-buyer">Datos personales</label>

    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-buyer check-disable-buyer" icon="check-circle"/>
    <label className="d-block text-center control-label align-bottom text-disable-buyer">Método de pago</label>


    </div>
    <div className="col-3 col-md-2">
    <MDBIcon className="size-icon-buyer check-disable-buyer" icon="check-circle"/>
    <label className="d-block text-center control-label align-bottom text-disable-buyer">Confirmación</label>

    </div>
    </Row>
    </div>

    <div className="offset-md-1">
    <Row className="mt-5">
    <Col md={11}>
    <h2 className="my-plan-buyer">DATOS DEL COMPRADOR</h2>
    </Col>

    </Row>
    <Form ref={c => { this.form = c }} onSubmit={this.handleForm.bind(this)}>
    <Row className="mt-4">
    <Col md={10}>
    <label className="select-label-buyer">Email</label>
    <Input
    type="email"
    className="form-control input-form input-h-buyer"
    name="email"
    placeholder="Email"
    value={this.state.email}
    onChange={this.onChangeEmail}
    validations={[required, validEmail]}
    />

    </Col>
    </Row>

    <Row className="mt-3">
    <Col md={5}>
    <label className="select-label-buyer">Nombres</label>
    <Input
    type="text"
    className="form-control input-form input-h-buyer"
    name="name"
    value={this.state.name}
    placeholder="Nombres"
    onChange={this.onChangeName}
    validations={[required]}
    />

    </Col>
    <Col md={5}>
    <label className="select-label-buyer">Apellidos</label>

    <Input
    type="text"
    className="form-control input-form input-h-buyer"
    name="last_name"
    value={this.state.lastName}
    placeholder="Apellidos"
    onChange={this.onChangeLastName}
    validations={[required]}
    />

    </Col>
    </Row>

    <Row className="mt-3">
    <Col md={5}>
    <label className="select-label-buyer">Número celular</label>

    <Input
    type="number"
    className=" form-control input-form input-h-buyer"
    name="phone"
    placeholder="Número celular"
    value={this.state.phone}
    onChange={this.onChangePhone}
    validations={[required, lengthMinPhone, lengthMax]}
    />

    </Col>
    <Col md={2}>
    <label className="select-label-buyer">Documento</label>

    <Select name='document' onChange={this.onChangeTypeDocument} className="input-h-buyer form-control input-form" value={this.state.typeDocument} validations={[required]}>
    <option value=''>Seleccione tipo de documento</option>
    <option value="1">Cedula de ciudadanía</option>
    <option value="2">Cedula de extranjera</option>
    <option value="3">Tarjeta de identidad</option>
    </Select>
    </Col>
    <Col md={3}>
    <label className="select-label-buyer">Número</label>

    <Input
    type="number"
    className=" form-control input-form input-h-buyer"
    name="document"
    placeholder="Número"
    value={this.state.document}
    onChange={this.onChangeDocument}
    validations={[required, lengthMinDoc, lengthMax]}
    />

    </Col>
    </Row>


    <Row className="mt-4">
    <Col md={10}>
    <p className="description-menu-buyer">Esta opción solo es válida para clientes que tengan acceso a su banco y efectuar transacciones online. Después de finalizar la transacción, el banco enviará la confirmación del pago en un periodo no superior a dos días hábiles. Así que su pedido será procesado después de la confirmación del banco.</p>

    </Col>
    </Row>

    <Row className="mt-4">
    <Col md={10}>
    <button id="submit" disabled={this.state.handled_form} value="Submit" type="submit" className="btn btn-green-steps-buyer float-right">PAGAR
    <img className="image ml-1" src="../after.png" alt="next"/>
    <img className="image ml-1" src="../after-green.png" alt=""/>
    </button>
    </Col>

    </Row>
    <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c }} />
    </Form>

    {this.props.message && (
      <div className="form-group">
      <div className={ this.state.successful ? "texts-alerts alert" : "texts-alerts alert color-danger" } role="alert">
      { this.props.message }
      </div>
      </div>
    )}

    <div className="offset-4">
    <Row className="mt-5">
    <Col md={4}>
    <p className="copyright-buyer">ALMORZADIARIO © 2021</p>
    </Col>
    </Row>
    </div>

    </div>

    </Col>

    </Row>

    { this.state.loginModal &&
      <LoginModal
      modalOpen={this.state.modalOpen}
      handleModalOpen={this.handleModalOpen}
      handleChangeModal={this.handleChangeModal}
      handleChangeModalPassword={this.handleChangeModalPassword}
      orderInProgress={true}
      saveOrder ={this.saveOrder}
      />
    }

    { this.state.signupModal &&
      <SingUpModal
      modalOpen={this.state.modalOpen}
      handleModalOpen={this.handleModalOpen}
      handleChangeModal={this.handleChangeModal}
      orderInProgress={true}
      user_order={this.state.user_recommendation}
      saveOrder={this.saveOrder}
      />
    }

    { this.state.passwordModal &&
      <PasswordModal
      modalOpen={this.state.modalOpen}
      handleModalOpen={this.handleModalOpen}
      handleChangeModal={this.handleChangeModalPassword}
      />
    }

    { this.state.activePay &&
      <WebCheckOut id_order={this.state.id_order} isLoggedIn={this.props.isLoggedIn}/>
    }

    </div>
  )
}
}

const mapDispatchToProps = dispatch => ({
  getPlanInView: (data) => {
    return dispatch(getPlan(data))
  },
  addOrderInView: (name, lastName, email, phone, document, typeDocument) => {
    return dispatch(addOrder(name, lastName, email, phone, document, typeDocument))
  },
  getLastBuyerInfoInView: () => {
    return dispatch(getLastBuyerInfo())
  },
  getPropetyInView: (value) => {
    return dispatch(getPropety(value))
  },
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  },
  removeOrderInView: () => {
    return dispatch(removeInternalOrder())
  },
  clearMessage: () => {
    dispatch(clearMessage())
  },
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  message: state.message ? state.message.message : ""
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BuyerData);
