import React, { useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "../../core/sass/forms.scss";
import { getPropety } from "../../actions/order";
import { register } from "../../actions/auth";
import { samePassword, required, vpassword, validEmail, samePasswordSecond} from "../../core/validations";
import { useDispatch, useSelector } from "react-redux";
import {withRouter} from 'react-router-dom';


const SingUp = (props) => {
  const { isLoggedIn } = useSelector(state => state.auth);

  const form = useRef();
  const checkBtn = useRef();
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);

  let [name, setName] = useState("");
  let [lastName, setlastName] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [terms, setTerms] = useState("");
  let [passwordTwo, setPasswordTwo] = useState("");
  let [phone, setPhone] = useState("");

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  let user = dispatch(getPropety("user_recommendation"))

  // autocomplete fields
  if (user) {
    if (user.name && name === "") {
      name = user.name
      setName(user.name);
    }

    if (user.lastName && lastName === "") {
      setlastName(user.lastName);
    }

    if (user.phone && phone === "") {
      setPhone(user.phone);
    }

    if (user.email && email === "") {
      setEmail(user.email);
    }
  }


  const onChangeName = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const onChangeLastName = (e) => {
    const value = e.target.value;
    setlastName(value);
  };

  const onChangePhone = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const onChangeTerms = (e) => {
    const value = e.target.value;
    setTerms(value);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePasswordTwo = (e) => {
    const password = e.target.value;
    setPasswordTwo(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setLoading(true);
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(name, lastName, email, password, phone))
      .then(() => {
        setLoading(false);
        setSuccessful(true);
        if (props.orderInProgress && props.orderInProgress === true) {
          props.handleModalOpen()
          props.saveOrder()
        } else {
          props.history.push("/");
          window.location.reload();
        }
      })
      .catch(() => {
        setSuccessful(false);
        setLoading(false);
      });
      }
    }


  let showLogin = false;

  if (!isLoggedIn && (
    props.location.pathname === "/profile" ||
    props.location.pathname === "/" ||
    props.location.pathname === "/choose-plan" )) {
      showLogin = true
    }

    return (
      <>
      <Modal className="modal-background" size="sm" md="6" show={showLogin || props.modalOpen} onHide={props.handleModalOpen}>
      <Modal.Header  closeButton>
      <Modal.Title className='text-center w-100 ml-4'>REGÍSTRATE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="form-background">

      <Form onSubmit={handleRegister} ref={form}>
      <Row className='mb-3' md={12}>
      <Col className="input-left" md={6}>

      <label className="select-label-buyer my-0">Nombres</label>
      <Input
      type="text"
      className="mb-3 mb-md-0 form-control input-form"
      name="name"
      value={name}
      placeholder="Nombre"
      onChange={onChangeName}
      validations={[required]}
      />

      </Col>
      <Col className="input-right" md={6}>

      <label className="select-label-buyer my-0">Apellidos</label>
      <Input
      type="text"
      className="form-control input-form"
      name="last_name"
      value={lastName}
      placeholder="Apellido"
      onChange={onChangeLastName}
      validations={[required]}
      />

      </Col>
      </Row>

      <Row className='mb-3' md={12}>
      <Col className="input-left" md={6}>

      <label className="select-label-buyer my-0">Email</label>
      <Input
      type="email"
      className="mb-3 mb-md-0 form-control input-form"
      name="email"
      placeholder="Email"
      value={email}
      onChange={onChangeEmail}
      validations={[required, validEmail]}
      />

      </Col>
      <Col className="input-right" md={6}>

      <label className="select-label-buyer my-0">Número celular</label>
      <Input
      type="number"
      className="form-control input-form"
      name="phone"
      placeholder="Número celular"
      value={phone}
      onChange={onChangePhone}
      validations={[required]}
      />
      </Col>
      </Row>

      <Row className='mb-4' md={12}>
      <Col className="input-left" md={6}>

      <label className="select-label-buyer my-0">Contraseña</label>
      <Input
      type="password"
      className="mb-3 mb-md-0 form-control input-form"
      name="password"
      placeholder="Contraseña"
      value={password}
      onChange={onChangePassword}
      validations={[required, vpassword, samePasswordSecond]}
      />

      </Col>

      <Col className="input-right" md={6}>
      <label className="select-label-buyer my-0">Confirmar contraseña</label>
      <Input
      type="password"
      className="form-control input-form"
      name="passwordTwo"
      placeholder="Confirmar contraseña"
      value={passwordTwo}
      onChange={onChangePasswordTwo}
      validations={[required, vpassword, samePassword]}
      />

      </Col>
      </Row>

      <p className="text-center text-terms">

      <input
      required={true}
      type="checkbox"
      className="checkbox-form"
      name="check-terms"
      value={terms}
      onChange={onChangeTerms}
      validations={[required]}
      /> Al regístrarme acepto <a rel="noopener noreferrer" href="https://almorzadiario.com/wp-content/uploads/2020/11/politicas.pdf"  className="links" target={"_blank"}> Términos y condiciones </a>
      </p>

      <div className="text-center mt-6">
        <button type="submit" className={loading ? "btn btn-green-loading" : "btn btn-green"}><img src="../loading.gif" className={loading ? "d-inline" : "d-none"} alt="loading" />{loading ? "CARGANDO" : "REGISTRAME"}</button>
      </div>

      {message && (
        <div className={ successful ? "texts-alerts mt-3 color-success text-center" : "texts-alerts mt-3 color-danger text-center" } role="alert">
          {message}
        </div>
      )}


      <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>

      </Modal.Body>
      <p className="text-center text-account">Ya tengo una cuenta,
      <span className="links" onClick={props.handleChangeModal}> Ingresar</span>
      </p>
      </Modal>
      </>
    );
  }

  export default withRouter(SingUp);
