import React from "react";
import { isEmail } from "validator";

export const required = (value) => {
  if (!value) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        ¡Requerido!
      </div>
    );
  }
};

export const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        !Formato invalido!
      </div>
    );
  }
};

export const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        Debe tener de 6 a 40 caracteres.
      </div>
    );
  }
};

export const inputlength = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        Debe tener entre 3 y 20 caracteres.
      </div>
    );
  }
};

export const lengthMinPhone = (value) => {
  if (value.length < 7) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        Debe tener más de 7 caracteres.
      </div>
    );
  }
};

export const lengthMinDoc = (value) => {
  if (value.length < 7) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        Debe tener más de 7 caracteres.
      </div>
    );
  }
};

export const lengthMax = (value) => {
  if (value.length > 20) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        Debe tener menos de 7 caracteres.
      </div>
    );
  }
};

export const samePassword = (value) => {
  let password = document.getElementsByName("password")[0].value

  if (value !== password) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        ¡Las contraseñas no coinciden!
      </div>
    );
  }
};

export const samePasswordSecond = (value) => {
  let password = document.getElementsByName("passwordTwo")[0].value

  if (password && password.length && value !== password) {
    return (
      <div className="alert-mw texts-alerts color-danger" role="alert">
        ¡Las contraseñas no coinciden!
      </div>
    );
  }
};
