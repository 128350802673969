import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../actions/types";

import {
  localStorageUserKey,
  localStorageOrderKey
} from "../services/keys-localstorage";

const user = JSON.parse(localStorage.getItem(localStorageUserKey));

let tokenExpired = false
let now = new Date().getTime()

if (user && user.expire_token_at && now < user.expire_token_at) {
  tokenExpired = true
} else if (user && user.expire_token_at === undefined) {
  tokenExpired = true
}

if (tokenExpired) {
  localStorage.removeItem(localStorageUserKey);
  localStorage.removeItem(localStorageOrderKey);
}

const initialState = user && !tokenExpired
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
