import React, { useState, useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useDispatch, useSelector } from "react-redux";
import {withRouter} from 'react-router-dom';
import { password } from "../../actions/auth";
import { required } from "../../core/validations";
import "../../core/sass/forms.scss";

const ForgotPassword = (props) => {

  const { isLoggedIn } = useSelector(state => state.auth);
  const form = useRef();
  const checkBtn = useRef();

  const [email, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const handlePassword = (e) => {
    e.preventDefault();

    setLoading(true);
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(password(email))
      .then(() => {
        setLoading(false);
        setSuccessful(true);
      })
      .catch(() => {
        setLoading(false);
        setSuccessful(false);

      });
    } else {
      setLoading(false);
      setSuccessful(false);
    }
  }

  const onChangeUsername = (e) => {
    const email = e.target.value;
    setUsername(email);
    console.log(email);
  };

  let showLogin = false;

  if (!isLoggedIn && (
    props.location.pathname === "/profile" ||
    props.location.pathname === "/" ||
    props.location.pathname === "/choose-plan" )) {
      showLogin = true
    }

    return (
      <>
      <Modal className="modal-background" size="sm" md="6" show={showLogin || props.modalOpen} onHide={props.handleModalOpen}>
      <Modal.Header  closeButton className="with-100">

      <Modal.Title className='text-center w-100'>
      { (props.orderInProgress && props.orderInProgress) ? "¡Ahora dinos quien eres!" : "INICIAR SESIÓN!"}
      </Modal.Title>

      </Modal.Header>
      <Modal.Body className="form-background">
      <Form onSubmit={handlePassword} ref={form}>
      <Input
      type="email"
      className="form-control input-form"
      name="email"
      placeholder = "Email"
      value={email}
      onChange={onChangeUsername}
      validations={[required]}
      />


      <p className="text-email mt-2">Se le enviará las <span className="code-email">credenciales</span> para acceder de forma segura a tu cuenta.</p>

      <div className="text-center mt-6" disabled={loading}>
        <button type="submit" className={loading ? "btn btn-green-loading" : "btn btn-green"}><img src="../loading.gif" className={loading ? "d-inline" : "d-none"} alt="loading" />{loading ? " CARGANDO" : "RECUPERAR"}</button>
      </div>


      {message && (
        <div className={ successful ? "texts-alerts mt-3 color-success text-center" : "texts-alerts mt-3 color-danger text-center" } role="alert">
          {message}
        </div>
      )}

    <p className="mt-4 text-account"><span className="links" onClick={props.handleChangeModal} > Regresar</span></p>

    <CheckButton style={{ display: "none" }} ref={checkBtn} />
    </Form>

    </Modal.Body>
    </Modal>
    </>
  );
}

export default withRouter(ForgotPassword)
