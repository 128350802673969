
let dev = {
  "HOST": "https://dev.server.almorzadiario.com/api/",
  "WEB_ROOT": "https://almorzadiario.com",
  "HOST_PAYU": "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/",
}

let prod = {
  "HOST": "https://server.almorzadiario.com/api/",
  "WEB_ROOT": "https://almorzadiario.com",
  "HOST_PAYU": "https://checkout.payulatam.com/ppp-web-gateway-payu/",
}

export const config = (process.env.NODE_ENV === "development") ? dev : prod;;
