import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PASSWORD_SUCCESS,
  PASSWORD_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";

export const register = (name, lastName, email, password, phone) => (dispatch) => {
  let data = {name, last_name: lastName, username: email, email, password, phone_number: phone }
  return AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response },
      });
      
      dispatch({
        type: SET_MESSAGE,
        payload: "¡Se ha creado la cuenta!",
      });

      return Promise.resolve();
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        let status = -1

        if (error.status) {
          status = error.status
        } else if (error.data && error.data.status) {
          status = error.data.status
        } else if (error.response && error.response.data && error.response.data.status) {
          status = error.response.data.status
        }

        if (status && status === 409) {
          message = "Ya tienes una cuenta registrada."
        }

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateUser = (name, lastName, email, phone, document, typeDocument, password, newPassword) => (dispatch) => {
  let data = { name,
               last_name: lastName,
               username: email,
               email,
               phone_number: phone,
               document_number: document,
               type_document: typeDocument,
               password: password,
               new_password: newPassword
   }

  return AuthService.updateUser(data).then(
    (response) => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "¡Datos guardados!",
      });

      return Promise.resolve();
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        let status = -1

        if (error.status) {
          status = error.status
        } else if (error.data && error.data.status) {
          status = error.data.status
        } else if (error.response && error.response.data && error.response.data.status) {
          status = error.response.data.status
        }

        if (status && status === 409) {
          message = "El correo ya esta en uso"
        }

        if (status && status === 401) {
          message = "Contraseña actual incorrecta"
        }

      dispatch({
        type: UPDATE_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  let data = {username, password}
  return AuthService.login(data).then(
    (response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: response },
      });

      return Promise.resolve();
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

        let status = -1

        if (error.status) {
          status = error.status
        } else if (error.data && error.data.status) {
          status = error.data.status
        } else if (error.response && error.response.data && error.response.data.status) {
          status = error.response.data.status
        }

        if (status && status === 401) {
          message = "¡Contraseña o Correo incorrecto!"
        }

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const password = (email) => (dispatch) => {
  let data = {email}
  return AuthService.password(data).then(
    (response) => {
      dispatch({
        type: PASSWORD_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Contraseña enviada al correo proporcionado",
      });

      return Promise.resolve();
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.status  && error.status === 404) {
        message = "¡El usuario no existe!"
      }

      dispatch({
        type: PASSWORD_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
   });
};
