import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashRouter as Router , Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import dashboard from "./components/dashboard/dashboard"
import profile from "./components/dashboard-profile/profile"
import name from "./components/step-name/stepName"
import feeding from "./components/step-feeding/stepFeeding"
import amount from "./components/step-amount/stepAmount"
import plan from "./components/step-plan/stepPlan"
import chooseMenu from "./components/choose-menu/chooseMenu"
import chooseMenuMonth from "./components/choose-menu/chooseMenu"
import choosePlan from "./components/chosen-plan/choosenPlan"
import buyerData from "./components/buyer-data/buyerData"
import confirmedOrder from "./components/confirmed-order/confirmedOrder"

import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

    return (

      <Router>
        <Helmet>
          <title>AlmorzaDiario</title>
          <meta name="description" content="Planea tus comidas por mes. Escoge tu plan y luego tus recetas." />
          <meta name="keywords" content="Menú para el mes, Menú mensual, Menú para todos los días, Menú diario, Menú vegetariano, Menú para la semana, Recetario digital, Recetario descargable, Meal planning, Meal planner, Planea tus menús, Menús para almuerzos, Recetas para almuerzos, Recetas fáciles, Recetas para todos los días, ¿qué cocino hoy?, Ideas para el almuerzo, Almuerzos fáciles, Cocina fácil, Cocina para todos los días, Recetas de cocina, Comidas fáciles, Planeador de comidas, Recetas para el  almuerzo, Comida fácil y rica, Almuerzo diario, Recetas económicas"/>
        </Helmet>
        <div className="container-fluid">
          <Route path="/" exact component={dashboard}/>
          <Route path='/profile' component={profile}/>
          <Route path='/create-order' component={name}/>
          <Route path='/feeding' component={feeding}/>
          <Route path="/amount" component={amount}/>
          <Route path="/plan" component={plan}/>
          <Route path="/choose-menu" exact component={chooseMenu}/>
          <Route path="/choose-menu/order/:id_order/month/:month/diet/:diet" exact component={chooseMenuMonth}/>
          <Route path="/choose-plan/:id" component={choosePlan}/>
          <Route path="/buyer-data/:id" component={buyerData}/>
          <Route path="/confirmed-order" component={confirmedOrder}/>
        </div>

      </Router>
    );

}

export default App;
