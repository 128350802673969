import _ from 'lodash'
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PdfModal from '../../components/pdf-modal/pdfModal';
import PayOrder from '../../components/pay-orden/PayOrden';
import { Link } from "react-router-dom";
import Navbar from '../../components/navbar-gray/navbarGray';
import { connect } from "react-redux";
import { getHistory, getPDF } from "../../actions/order";
import { logout } from "../../actions/auth";

import Skeleton from 'react-loading-skeleton';

import { saveAs } from 'file-saver';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'font-awesome/css/font-awesome.min.css';
import '../../components/dashboard/dashboard.scss';

class Dashboard extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalOpenPdf: false,
      modalOpenPayOrder: false,
      isLoading: true,
      data: [],
      error: null,
      id_order: 0,
      id_diet: 1,
      currentMonth: 1,
      order_date: null,
      activePay: false,
      count_monts: 1,
      downlodingFile: false,
      messageNext: ""
    };
    this.handleEvent = this.handleEvent.bind(this)

    toast.configure()

    this.topReference = React.createRef()
  }

  componentDidMount() {
    document.querySelector('.background-left').style = 'background-color: #E9E1D0'
    document.querySelector('.background-right').style = 'background-color: #F0ECE3'
    document.querySelector('.background-right-resp').style = 'background-color: #E9E1D0'

    if (this.props.isLoggedIn) {
      this.fetchHistory();
    }
  }

  fetchHistory() {
    this.setState({ isLoading: true})

    this.props.getHistoryInView()
    .then(data => {
      this.setState({
        data: data,
        isLoading: false,
      })

      const query = new URLSearchParams(this.props.location.search);
      let order_id = query.get('order')

      if (order_id && order_id != null && data.length) {
        let order = _.find(data, {id: parseInt(order_id)});

        if (order) {
          const {id, created, plan, status, totalRecipes} = order;
          const currentMonth = this.calculateCurrentMonth(totalRecipes);

          this.handleOpenPdf(id, plan.count_monts, status, currentMonth, created)
        }
      }
    }
    )
    .catch(error => this.setState({ error, isLoading: false }));
  }

  handleOpenPdf  = (id_order, count_monts, status, currentMonth, created, id_diet) => {
    if (status !== "Pagado") {
      let error = { message: "¡No se ha proporcionado el pago!"}
      this.setState({ error })

      let msgError = 'El pago se encuentra en estado de validación.'

      if (status === "Rechazado") {
        msgError = "El pago se encuentra rechazado. No puedes descargar el PDF."
      }

      toast.error(msgError, { toastId: "error-status", autoClose: false, progressClassName: 'fancy-progress-bar', position: toast.POSITION.BOTTOM_RIGHT, onClick: () => {
                        this.topReference.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }})

      return;
    }

    this.setState({ error: null })

    // Plan is only one month (modal is not open)
    if (count_monts === 1) {
      this.downloadPDF(id_order, 1)
      return;
    }

    this.setState((prevState) => {
      return {
        modalOpenPdf: !prevState.modalOpenPdf,
        id_order: id_order, // for PDF modal
        id_diet: id_diet, // for PDF modal
        currentMonth: currentMonth, // for PDF modal
        order_date: created,
        count_monts: count_monts,
        messageNext: ""
      }
    })
  }

  handleOpenPayOrder  = (id_order) => {
    this.setState((prevState) => {
      return{
        modalOpenPayOrder: !prevState.modalOpenPayOrder,
        id_order: id_order, // for PDF modal
      }
    })
  }

  handleModalPDFOpen = () => {
    this.setState((prevState) => {
      return{
        modalOpenPdf: !prevState.modalOpenPdf
      }
    })
  }

  changeMessageNextMonth = (message) => {
    this.setState((prevState) => {
      return{
        messageNext: message
      }
    })
  }

  handleEvent(eventKey){
    if (eventKey === "1") {
      this.props.history.push('/create-order')
    }else if (eventKey === "2") {
      this.props.history.push('/profile')
    }else  {
      this.props.history.push('/')
    }
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  downloadPDFBlob(pdfBase, nameFile) {
    let buffer = this._base64ToArrayBuffer(pdfBase)

    let file = new File([buffer], nameFile, {type: "application/pdf;charset=utf-8"});

    saveAs(file);
  }

  downloadPDFLink(pdfBase, nameFile){
    const linkSource = `data:application/pdf;base64,${pdfBase}`;
    const a = document.createElement("a");

    a.href = linkSource;
    a.download = nameFile;
    a.target = "_blank";
    a.style.display = 'none'

    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  }

  downloadPDF = (id_order, month) => {
    this.setState({ isLoading: true, downlodingFile: true})

    let data = { id_order, month}

    const isWebView = navigator.userAgent.includes ('wv')

    if (isWebView) {
      toast.error('¡Para descargar tu PDF, abre Almorzadiario desde cualquier navegador! Google Chrome, Mozilla Firefox u otro.¡Para descargar tu PDF, abre Almorzadiario desde cualquier navegador! Google Chrome, Mozilla Firefox u otro.', { toastId: "error-download", autoClose: false, progressClassName: 'fancy-progress-bar', position: toast.POSITION.BOTTOM_RIGHT})
      return;
    }

    this.props.getPDFInView(data)
    .then(data => {
      this.setState({isLoading: false, downlodingFile: false})

      if (data.status === 200 && data.file) {
        let fileObj = data.file
        let filename = data.file_name || "almorzadiario.pdf"

        // download PDF
        let isFileSaverSupported = false;

        try {
          isFileSaverSupported = !!new Blob();
        } catch (e) {
          console.log('Error: checking a blob', e);
        }

        if (isFileSaverSupported) {
          this.downloadPDFBlob(fileObj, filename)
        } else {
          this.downloadPDFLink(fileObj, filename)
        }

      } else if (data.status === 402) {
          let msg =  "¡El pago no se encuentra registrado!"
          let error = { message: msg}

          toast.error(msg, { toastId: "error-status", autoClose: false, progressClassName: 'fancy-progress-bar', position: toast.POSITION.BOTTOM_RIGHT, onClick: () => {
                            this.topReference.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }})

          this.setState({ error })
      } else {
        let msg =  "¡Ha ocurrido un error, intenta más tarde!"
        let error = { message: msg}
        toast.error(msg, { toastId: "error-unknown", autoClose: false, progressClassName: 'fancy-progress-bar', position: toast.POSITION.BOTTOM_RIGHT})

        this.setState({ error })
      }

    }
    )
    .catch(error => this.setState({ error, isLoading: false, downlodingFile: false }));
  }

  calculateCurrentMonth = (data) => {
    let month = 1

    for (var i = 0; i < data.length; i++) {
      if (data[i].month_number && data[i].month_number > month) {
        month = data[i].month_number
      }
    }

    return month
  }

  renderSkeleton() {
    const loadingGrid = [1, 2, 3, 4, 5];
    return (loadingGrid.map(item => { return (
      <Row className="my-3">

      <Col className={"offset-md-1"} md={3} xs={{ span: 10, offset: 1 }}>
      <p className="my-0 py-0 history-dash"><Skeleton /></p>
      <p className="my-0 py-0 history-desc-dash"> <Skeleton count={3}/></p>
      </Col>
      <Col md={2} className="d-none d-md-block">
      <p className="my-0 py-0 history-dash"><Skeleton /></p>
      <p className="my-0 py-0 history-desc-dash"><Skeleton count={3}/></p>

      </Col>

      <Col md={3} className="d-none d-md-block">
      <p className="my-0 py-0 history-dash"><Skeleton /></p>
      <Skeleton />
      </Col>

      <Col md={1} className="d-none d-md-block">
      <p className="my-0 py-0 history-dash"><Skeleton duration={1} /></p>
      <p className="my-0 py-0 history-desc-dash"><Skeleton duration={1} /> </p>
      </Col>

      <Col className="mt-3 mt-md-0 d-none d-md-block" md={1}>
      <Skeleton circle={true} height={50} width={50} />

      </Col>

      </Row>
      )}
    )
  )
  }

  render() {
    const { isLoading, data, error, downlodingFile } = this.state;
    return (

      <div>
      <section>
      <Navbar/>
      </section>
      <Row className="height-view">
      <Col className="background-left-dash" md={4}>
      <Row className="mt-5 mb-5 mb-md-0">
      <div className="offset-md-4 offset-1">
      <Col md={12}>
      <h2 className="salute-dash">HOLA, { this.props.namePerson ? this.props.namePerson.toUpperCase() : "" }</h2>
      </Col>
      </div>
      </Row>

      <Row className="mt-5 d-none d-sm-none d-md-block">
      <div className="offset-md-4 offset-1">
      <Col md={12}>
      <div className="separator-dash">
      <Link to='/create-order'>
      <p className="dashboard-item-dash">Crear recetario</p>
      </Link>
      </div>
      <div className="separator-dash">
      <Link to='/profile'>
      <p className="dashboard-item-dash">Perfil</p>
      </Link>
      </div>
      <div className="separator-dash">
      <Link to='/'>
      <p className="active-dash dashboard-item-dash">Historial</p>
      </Link>
      </div>
      <p onClick={this.props.logOut} className="cursor-dash dashboard-item-dash">Salir</p>
      </Col>
      </div>
      </Row>

      </Col>

      <Col className="background-right-dash" md={8}>


      <Row className="mt-5">
      <div className="offset-1">
      <Col md={12}>
      <h2 className="salute-dash" ref={this.topReference} >HISTORIAL DE PEDIDOS</h2>
      </Col>
      </div>

      </Row>

      <Row className="mt-1">
        <div className="offset-1">
        <Col md={12}>
          {error ? <p>{error.message ? error.message : "¡Un error inesperado ha ocurrido!" }</p> : null}
          { !isLoading && !data.length ? (<p> ¡No tienes ordenes!</p> ) : null}
        </Col>
        </div>

      </Row>

      <Row className="mt-1">
      <div className="offset-1">
        <Col md={12}>
          { downlodingFile ? (<div className={"texts-alerts"}> Generando PDF. Esto puede tomar unos minutos. Por favor espera sin refrescar la pantalla.</div>) : null }
        </Col>
        </div>

      </Row>


      {!isLoading && data.map ? (
        data.map(item => {
          const {id, price, id_diet, created, plan, status, totalRecipes} = item;
          const currentMonth = this.calculateCurrentMonth(totalRecipes);

          let payClassName = (status === "Pagado") ? "active-pay": "inactive-pay"

          return (
            <div className="offset-1">

            <Row className="mt-4">

            <Col md={3}>
            <p className="my-0 py-0 history-dash">Detalles de la compra</p>
            <p className="my-0 py-0 history-desc-dash">Pedido #: {id}</p>
            </Col>
            <Col md={2}>
            <p className="my-0 py-0 history-dash">Fecha</p>
            <p className="my-0 py-0 history-desc-dash">{created}</p>

            </Col>

            <Col md={3}>
            <p className="my-0 py-0 history-dash">Medio de pago</p>
            <p className="my-0 py-0 history-desc-dash">PAY Latam
              <span className="ml-5">US ${price}</span>
            </p>
            </Col>

            <Col md={1}>
            <p className="my-0 py-0 history-dash">Estado</p>
            <p className={"my-0 py-0 pay " + payClassName } >{status} </p>
            </Col>

            <Col className="mt-3 mt-md-0" md={1}>
              <button data-id={id} data-currentmonth={currentMonth} data-month={plan.count_monts} className="btn-action-dash"
              onClick={status.toLowerCase() === "declinada" ? () => this.handleOpenPayOrder(id) : () => this.handleOpenPdf(id, plan.count_monts, status, currentMonth, created, id_diet)}>
              {status.toLowerCase() === "pagado" ?
                <div>
                  <img src="../download.png"  className="image-dow" alt=""/>
                  <img src="../hover-dow.png"  className="image-dow" alt=""/>
                </div>: <img src="../disable-dow.png" alt=""/>
              }
              </button>

            </Col>
            </Row>


            </div>

          );
        })
      ) : ( isLoading ? this.renderSkeleton() : null ) }

      <div className="offset-md-5">
      <Row className="mt-5">
      <Col md={4}>
      <p className="text-center copyright-dash">ALMORZADIARIO © 2021</p>
      </Col>
      </Row>
      </div>

      </Col>

      </Row>

      <PdfModal
      modalOpen={this.state.modalOpenPdf}
      downloadPDF= {this.downloadPDF}
      handleModalOpen={this.handleModalPDFOpen}

      id_order={this.state.id_order}
      id_diet={this.state.id_diet}
      currentMonth={this.state.currentMonth}
      order_date = {this.state.order_date}
      messageNext = {this.state.messageNext}
      changeMessageNextMonth ={this.changeMessageNextMonth}
      count_monts={this.state.count_monts}
      />

      <PayOrder
      modalOpen={this.state.modalOpenPayOrder}
      handleModalOpen={this.handleOpenPayOrder}
      id_order={this.state.id_order}

      />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getHistoryInView: () => {
    return dispatch(getHistory())
  },
  getPDFInView: (data) => {
    return dispatch(getPDF(data))
  },
  logOut: () => {
    return dispatch(logout())
  }
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  namePerson: (state.auth && state.auth.user) ? state.auth.user.name : ""
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
