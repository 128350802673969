import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';

import { getItemsWebCheckout } from "../../actions/order";
import { connect } from "react-redux";

import {config } from '../../config'
const HOST_PAYU = config.HOST_PAYU;

class WebCheckOut extends Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.fetchInputs();
    }
  }

  fetchInputs() {
    this.setState({ isLoading: true})

    this.props.getItemsWebCheckoutInView({id_order: this.props.id_order})
    .then(data => {
      this.setState({
        data,
        isLoading: false,
      }, () => {
        // End load inputs
        document.getElementById('form-payu').submit();
      })
    }
    )
    .catch(error => this.setState({ error, isLoading: false }));
 }

  render() {
    const { isLoading, data, error } = this.state;

    return (
      <form id="form-payu"  action={HOST_PAYU} method="post">
          <Row className="mt-1">
              {error ? <p>{error.message ? error.message : "¡Un error inesperado ha ocurrido!" }</p> : null}
          </Row>

          { !isLoading ? (Object.keys(data).map((key) => {

           return (
                  <input name={key} type="hidden"  value={data[key]} />
                    )})
         ) : (null)}
      </form>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getItemsWebCheckoutInView: (data) => {
    return dispatch(getItemsWebCheckout(data))
  }
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn
});

export default connect(mapStateToProps, mapDispatchToProps)(WebCheckOut);
