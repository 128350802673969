import {
  localStorageUserKey
} from "./keys-localstorage";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem(localStorageUserKey));

  if (user && user.token) {
    return user.token
  } else {
    return "";
  }
}
