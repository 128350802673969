import {
  GET_PLAN,
  GET_PLANS,
} from "./types";

import PlanService from "../services/plan.service";

export const getPlans = () => (dispatch) => {
  dispatch({
    type: GET_PLANS,
   });

  return PlanService.getPlans();
};

export const getPlan = (data) => (dispatch) => {
  dispatch({
    type: GET_PLAN,
   });

  return PlanService.getPlan(data);
};
