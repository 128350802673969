import Api from "../core/api";
import authHeader from "./auth-token";

import {
  localStorageUserKey,
  localStorageOrderKey,
} from "./keys-localstorage";

const updateUser = (data) => {
  return Api.exec("v1/users/change", { token: authHeader(), data} , "POST")
          .then((response) => {
            if (response.token) {
              localStorage.setItem(localStorageUserKey, JSON.stringify(response));
              return Promise.resolve(response);
            }

            return Promise.reject(response);
 });
};

const register = (data) => {
  return Api.exec("signup", {data} , "POST")
          .then((response) => {
            if (response.token) {
              localStorage.setItem(localStorageUserKey, JSON.stringify(response));
              return Promise.resolve(response);
            }

            return Promise.reject(response);
 });
};

const login = (data) => {
  return Api.exec("login", {data} , "POST")
          .then((response) => {
            if (response.token) {
              localStorage.setItem(localStorageUserKey, JSON.stringify(response));
              return Promise.resolve(response);
            }

            return Promise.reject(response);
 });
};

const password = (data) => {
  return Api.exec("site/reset-password", {data} , "POST")
          .then((response) => {
            if (response.status === 200) {
              return Promise.resolve(response);
            }

            return Promise.reject(response);
 });
};

const logout = () => {
  localStorage.removeItem(localStorageUserKey);
  localStorage.removeItem(localStorageOrderKey);
};

export default {
  register,
  login,
  logout,
  updateUser,
  password
};
