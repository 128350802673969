import React from 'react';
import Row from 'react-bootstrap/Row';
import Navbar from '../../components/navbar/navbar';
import { Link } from "react-router-dom"
import {Animated} from "react-animated-css";
import '../../components/step-feeding/stepFeeding.scss';
import { connect } from "react-redux";
import { getPropety } from "../../actions/order";
import { setPropety } from "../../actions/order";
import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"
import { Helmet } from 'react-helmet';

class StepFeeding extends React.Component{

  constructor(props){
    super(props);

    let diet = this.props.getPropetyInView("id_diet")

    if (diet === "" || diet === null) {
      diet = 0
    }

    this.state = {
      name: this.props.getPropetyInView("name_user"),
      diet: diet,

    }
    this.imageClick = this.imageClick.bind(this)
  }


  componentDidMount() {
    document.querySelector('.background-left').style = 'background-color: #ED4B72'
    document.querySelector('.background-right').style = 'background-color: #63C7B9'
    document.querySelector('.background-right-resp').style = 'background-color: #ED4B72'

    // return to before step if is not fill in
    let currentStep = 2
    if (!checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }

  }



  imageClick(d){
    this.setState({diet: d});
    if (this.props.setPropetyInView) {
      this.props.setPropetyInView(d, "id_diet")
      this.props.history.push('/amount')
    }
  }


  render(){

    return (

      <div>
      <Helmet>
          <title>tipo de alimentación</title>
          <meta name="description" content="Le preguntamos al usuario su tipo de dieta. Puede elegir entre dieta omnívora o vegetariana" />
      </Helmet>
      
      <section>
      <Navbar/>
      </section>

      <Row className="height-view">
        <div className="background-left-feeding col-md-6">
          <div className="offset-md-2 mb-5">
            <div className="col-md-10 mt-5">

              <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={0} isVisible={true}>
                <Link to="/create-order">
                  <p className="text-back-feeding"><img src="../back.png" alt="volver"/> Volver</p>
                </Link>
                <h2 style={{ textTransform: 'uppercase'}} className="mt-4 text-init-feeding">¡MUY BIEN {this.state.name}!</h2>
              </Animated>
              <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={3000} animationOutDuration={1000} animationInDelay={1000} isVisible={true}>
                <h2 className="text-subinit-feeding">¿CUÁL ES TU TIPO DE ALIMENTACIÓN?</h2>
              </Animated>
            </div>
          </div>
        </div>

        <div className="background-right-feeding col-md-6">
          <div className="offset-md-2">
          <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animateOnMount={true} animationInDuration={3000} animationInDelay={1500} animationOutDuration={1000} isVisible={true}>
          <div className="img-button-feeding">
            <img onClick={() => this.imageClick(1)} src="../omnivora.png" className="img-resp-feeding mb-5" alt="feeding"/>
          </div>
          <div className="img-button-feeding">
            <img onClick={() => this.imageClick(2)} src="../vegetariana.png" className="img-resp-feeding mb-3" alt="feeding vegetarian"/>
          </div>
          </Animated>
          </div>
        </div>

      </Row>
      </div>
    )
  }
}

const mapStateToProps = dispatch => ({
  getPropetyInView: (value) => {
    return dispatch(getPropety(value))
  },

  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  }

});

export default connect(null, mapStateToProps)(StepFeeding);
